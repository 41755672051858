import axios from "axios";
import PropTypes from "prop-types";
import React, { ReactElement, useCallback, useEffect, useState } from "react";
import { authenticateToken } from "../../../utils/auth";
import { responseNotification } from "../../../utils/notify";
import { useParams } from "react-router";
import BrandLayout from "../Layout";
import { useDispatch } from "react-redux";
import { SET_BRAND_INFO } from "../../../redux/auth/authType";
import BrandProductList from "../../Product/index";

const BrandDetails = (): ReactElement => {
  const dispatch = useDispatch();
  const route = useParams();
  const [singleBrandInfo, setSingleBrandInfo] = useState<any>({
    loading: false,
    data: null,
  });

  const fetchBrandDetails = useCallback((getBrandId: any) => {
    if (getBrandId) {
      try {
        setSingleBrandInfo({ loading: true, data: null });
        axios
          .get(
            `${process.env.REACT_APP_CATALOG_READER_API}/admin/brand/detail?brandId=${getBrandId}`,
            {
              headers: {
                Authorization: `Bearer ${authenticateToken()}`,
                "Content-Type": "application/json",
              },
            }
          )
          .then((data) => {
            if (data.status === 200) {
              setSingleBrandInfo({
                loading: false,
                data: data?.data?.brand,
              });

              dispatch({
                type: SET_BRAND_INFO,
                payload: {
                  brandInfo: {
                    id: data?.data?.brand?.id,
                    name: data?.data?.brand?.name,
                    type: data?.data?.brand?.type,
                    logo: data?.data?.brand?.logo,
                    bannerImage: data?.data?.brand?.bannerImage,
                  },
                },
              });
            } else {
              setSingleBrandInfo({ loading: false, data: null });
              responseNotification(
                data.statusText || "something went wrong",
                "error"
              );
            }
          })
          .catch((err) => {
            setSingleBrandInfo({ loading: false, data: null });

            console.log(err);
          });
      } catch (error) {
        setSingleBrandInfo({ loading: false, data: null });
      }
    }
  }, []);
  useEffect(() => {
    fetchBrandDetails((route as any)?.brandId);
  }, [fetchBrandDetails]);

  const brand = singleBrandInfo?.data;

  return (
    <React.Fragment>
      <BrandLayout
        brandId={brand?.id}
        title={`Brand Details`}
        subTitle={`Details`}
      >
        <div className="content-body rounded-2xl">
          <BrandProductList inBrand={true} getBrandId={brand?.id} />
        </div>
      </BrandLayout>
    </React.Fragment>
  );
};

BrandDetails.propTypes = {
  match: PropTypes.object,
  params: PropTypes.object,
};
export default BrandDetails;
