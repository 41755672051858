import PropTypes from "prop-types";
import React, { ReactElement } from "react";
import MerchantLayout from "../Layout";
import ProductList from "../../../Product/index";
import { useSelector } from "react-redux";

const MerchantProductList = (): ReactElement => {
  const { campaignInfo } = useSelector((state) => (state as any)?.authReducer);
  return (
    <React.Fragment>
      <MerchantLayout
        campaignId={campaignInfo.id}
        title="Product List"
        subTitle="Details"
        extra={<></>}
      >
        <div className="content-body_ rounded-2xl">
          <div className="bg-white shadow-lg shadow-gray-200 rounded-2xl p-0 mb-6">
            <ProductList inCampaign={true} getCampaignId={campaignInfo.id} />
          </div>
        </div>
      </MerchantLayout>
    </React.Fragment> 
  );
};

MerchantProductList.propTypes = {
  match: PropTypes.object,
  params: PropTypes.object,
};
export default MerchantProductList;
