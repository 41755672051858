import axios from "axios";
import PropTypes from "prop-types";
import React, { ReactElement, useCallback, useEffect, useState } from "react";
import { authenticateToken } from "../../../../utils/auth";
import { responseNotification } from "../../../../utils/notify";
import { useParams } from "react-router";
import CampaignLayout from "../Layout";
import { useDispatch } from "react-redux";
import { SET_CAMPAIGN_INFO } from "../../../../redux/auth/authType";
import Loader from "../../../common/Loader";
//import CampaignProductList from "../Product";
import AssignCampaignProductList from "../Product/campaignAssignProductList";
import CampaignProductList from "../../../Product/index";
import { Button, Modal, Tabs } from "antd";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import TabPane from "antd/es/tabs/TabPane";
import moment from "moment";

const CampaignDetails = (): ReactElement => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const route = useParams();
  const [singleCampaignInfo, setSingleCampaignInfo] = useState<any>({
    loading: false,
    data: null,
  });

  const fetchCampaignDetails = useCallback((getCampaignId: any) => {
    if (getCampaignId) {
      try {
        setSingleCampaignInfo({ loading: true, data: null });
        axios
          .get(
            `${process.env.REACT_APP_CATALOG_READER_API}/admin/campaign/details/${getCampaignId}`,
            {
              headers: {
                Authorization: `Bearer ${authenticateToken()}`,
                "Content-Type": "application/json",
              },
            }
          )
          .then((data) => {
            if (data.status === 200) {
              setSingleCampaignInfo({
                loading: false,
                data: data?.data?.campaign,
              });
              dispatch({
                type: SET_CAMPAIGN_INFO,
                payload: {
                  campaignInfo: {
                    id: data?.data?.campaign?.id,
                    name: data?.data?.campaign?.name,
                    appsImg: data?.data?.campaign?.banner,
                    webImg: data?.data?.campaign?.bannerWeb,
                    status: data?.data?.campaign?.status,
                    description: data?.data?.campaign?.description,
                  },
                },
              });
            } else {
              setSingleCampaignInfo({ loading: false, data: null });
              responseNotification(
                data.statusText || "something went wrong",
                "error"
              );
            }
          })
          .catch((err) => {
            setSingleCampaignInfo({ loading: false, data: null });

            console.log(err);
          });
      } catch (error) {
        setSingleCampaignInfo({ loading: false, data: null });

        console.log(error, "error");
      }
    }
  }, []);

  useEffect(() => {
    fetchCampaignDetails((route as any)?.campaignId);
  }, [fetchCampaignDetails]);

  const campaign = singleCampaignInfo?.data;

  if (singleCampaignInfo.loading) return <Loader />;

  const onClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <CampaignLayout
        campaignId={campaign?.id}
        title={`Campaign Details`}
        subTitle={`Details`}
        extra={<></>}
      >
        <div className="content-body rounded-2xl">
          <div className="bg-white shadow-lg shadow-gray-200 rounded-2xl p-4">
            <Tabs
              onTabScroll={() => {
                direction: top;
              }}
              tabPosition="top"
              // tabBarExtraContent={
              //   <Link
              //     to={`/shops/${campaign?.id}/edit`}
              //     className="rounded-full h-8 w-8 border-2 border-white hover:bg-slate-50 text-center text-red-600 align-center"
              //   >
              //     <FontAwesomeIcon icon={faPenToSquare} />
              //     <span className="ml-2">Edit</span>
              //   </Link>
              // }
            >
              <TabPane tab="GENERAL INFO" key="General">
                <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                  <div>
                    <dt className="text-sm font-medium text-gray-900">
                      Campaign Name
                    </dt>
                    <dd className="text-sm font-semibold text-gray-500">
                      {campaign?.name}
                    </dd>
                  </div>

                  <div>
                    <dt className="text-sm font-medium text-gray-900">
                      Campaign Description
                    </dt>
                    <dd className="text-sm font-semibold text-gray-500">
                      {campaign?.description}
                    </dd>
                  </div>

                  <div>
                    <dt className="text-sm font-medium text-gray-900">
                      Start Date
                    </dt>
                    <dd className="text-sm font-semibold text-gray-500">
                      {moment(campaign?.startAt).format("lll")}
                    </dd>
                  </div>

                  <div>
                    <dt className="text-sm font-medium text-gray-900">
                      End Date
                    </dt>
                    <dd className="text-sm font-semibold text-gray-500">
                      {moment(campaign?.endAt).format("lll")}
                    </dd>
                  </div>

                  <div>
                    <dt className="text-sm font-medium text-gray-900">
                      Minimum Discount Type
                    </dt>
                    <dd className="text-sm font-semibold text-gray-500">
                      {campaign?.minimumDiscountType}
                    </dd>
                  </div>

                  <div>
                    <dt className="text-sm font-medium text-gray-900">
                      Minimum Discount
                    </dt>
                    <dd className="text-sm font-semibold text-gray-500">
                      &#2547; {campaign?.minimumDiscount || 0}
                    </dd>
                  </div>

                  <div className="sm:col-span-2__">
                    <dt className="text-md font-medium text-gray-900">
                      Merchant Offered Discount Type
                    </dt>
                    <dd className="mt-1 space-y-3 text-sm text-gray-500">
                      <p>{campaign?.merchantOfferedDiscountType}</p>
                    </dd>
                  </div>

                  <div className="sm:col-span-2__">
                    <dt className="text-md font-medium text-gray-900">
                      Merchant Offered Discount
                    </dt>
                    <dd className="mt-1 space-y-3 text-sm text-gray-500">
                      <p>{campaign?.merchantOfferedDiscount}</p>
                    </dd>
                  </div>
                </dl>
              </TabPane>
            </Tabs>
          </div>
        </div>
      </CampaignLayout>
    </React.Fragment>
  );
};

CampaignDetails.propTypes = {
  match: PropTypes.object,
  params: PropTypes.object,
};
export default CampaignDetails;
