import { Avatar, Layout } from "antd";
import { PageHeader } from "@ant-design/pro-layout";
import PropTypes from "prop-types";
import React, { ReactElement, useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getImgUrl } from "../../../../utils";
import { Link, useNavigate, useParams } from "react-router-dom";
import Sidebar from "./sidebar";
import axios from "axios";
import { authenticateToken } from "../../../../utils/auth";
import { responseNotification } from "../../../../utils/notify";
import moment from "moment";
const { Content } = Layout;

const CampaignLayout = ({
  children,
  campaignId,
  title,
  extra,
  subTitle,
}: {
  children: ReactElement;
  campaignId: string;
  title: string;
  subTitle: string;
  extra: ReactElement;
}): ReactElement => {
  const route = useParams();
  const navigate = useNavigate();
  const { campaignInfo } = useSelector((state) => (state as any)?.authReducer);

  const [singleCampaignInfo, setSingleCampaignInfo] = useState<any>({
    loading: false,
    data: null,
  });

  const fetchCampaignDetails = useCallback((getCampaignId: any) => {
    if (getCampaignId) {
      try {
        setSingleCampaignInfo({ loading: true, data: null });
        axios
          .get(
            `${process.env.REACT_APP_CATALOG_READER_API}/admin/campaign/details/${getCampaignId}`,
            {
              headers: {
                Authorization: `Bearer ${authenticateToken()}`,
                "Content-Type": "application/json",
              },
            }
          )
          .then((data) => {
            if (data.status === 200) {
              setSingleCampaignInfo({
                loading: false,
                data: data?.data?.campaign,
              });
            } else {
              setSingleCampaignInfo({ loading: false, data: null });
              responseNotification(
                data.statusText || "something went wrong",
                "error"
              );
            }
          })
          .catch((err) => {
            setSingleCampaignInfo({ loading: false, data: null });
            console.log(err);
          });
      } catch (error) {
        setSingleCampaignInfo({ loading: false, data: null });
        console.log(error, "error");
      }
    }
  }, []);

  useEffect(() => {
    fetchCampaignDetails((route as any)?.campaignId);
  }, [fetchCampaignDetails]);

  const campaign = singleCampaignInfo?.data;

  return (
    <React.Fragment>
      <Layout className="min-vh-100">
        <main>
          <div className="grid grid-cols-1 px-2 pt-4 xl:grid-cols-3 xl:gap-6">
            <div className="col-span-full">
              <PageHeader
                className="site-page-header px-0 py-0"
                onBack={() => navigate(-1)}
                title={
                  <h1 className="text-xl font-semibold text-gray-900 sm:text-2xl">
                    {title}
                  </h1>
                }
                extra={extra}
              />
              <nav className="flex mb-0" aria-label="Breadcrumb">
                <ol className="inline-flex items-center space-x-1 md:space-x-2">
                  <li className="inline-flex items-center">
                    <Link
                      to="/promotion/campaign"
                      className="inline-flex items-center text-gray-700 hover:text-gray-900"
                    >
                      Campaign
                    </Link>
                  </li>

                  <li>
                    <div className="flex items-center">
                      <svg
                        className="w-6 h-6 text-gray-400"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                          clipRule="evenodd"
                        ></path>
                      </svg>
                      <span
                        className="ml-1 text-sm font-medium text-gray-400 md:ml-2"
                        aria-current="page"
                      >
                        {subTitle}
                      </span>
                    </div>
                  </li>
                </ol>
              </nav>
            </div>

            <div className="col-span-full xl:col-auto">
              <div className="bg-white shadow-lg shadow-gray-200 rounded-2xl mb-6">
                <div className="sm:flex xl:block sm:space-x-4 xl:space-x-0">
                  <img
                    src={campaignInfo?.webImg}
                    alt=""
                    className="rounded-t-xl"
                  />
                  <div className="px-4 pb-2">
                    <div className="xl:-mt-14 flex justify-between items-baseline">
                      <div className="hidden lg:block">
                        <Avatar
                          size={100}
                          shape="circle"
                          src={getImgUrl(campaignInfo?.appsImg)}
                          className="mb-5 w-20 h-20 shadow-lg border-4 border-white bg-gray-50"
                        />
                      </div>
                      <div className="">
                        <div className="flex">
                          {campaign?.isFlashSale && (
                            <div className="bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 hover:from-pink-500 hover:to-yellow-500 text-white font-bold py-1 px-4 mr-2 rounded-full shadow-md">
                              {campaign?.isFlashSale && "FLASH SALE"}
                            </div>
                          )}
                          <div
                            className={`${
                              campaign?.isActive
                                ? "bg-gradient-to-r from-green-400 to-blue-500"
                                : "bg-gradient-to-r from-indigo-500 via-pink-500 to-red-500"
                            } " hover:from-pink-500 hover:to-yellow-500 text-white font-bold py-1 px-4 rounded-full shadow-md"`}
                          >
                            {campaign?.isActive ? "ACTIVE" : "INACTIVE"}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <h2 className="text-lg font-bold mb-2">
                        {campaignInfo?.name?.toUpperCase()}
                      </h2>
                      <ul className="mt-2 space-y-1 grid grid-cols-none gap-2 mb-4">
                        <li className="grid font-normal text-gray-500">
                          <span className="font-semibold mr-2">
                            Start Date:
                          </span>
                          {moment(campaign?.startAt).format("lll")}
                        </li>
                        <li className="grid font-normal text-gray-500">
                          <span className="font-semibold mr-2">End Date: </span>
                          {moment(campaign?.endAt).format("lll")}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <Sidebar campaignId={campaignId} />
            </div>
            <div className="col-span-2">
              <Layout>
                <Content className="main-content-layout">{children}</Content>
              </Layout>{" "}
            </div>
          </div>
        </main>
      </Layout>
    </React.Fragment>
  );
};

CampaignLayout.propType = {
  children: PropTypes.element,
};

export default CampaignLayout;
