import { Avatar, Button, Form, Steps } from "antd";
import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { authenticateToken } from "../../../utils/auth";
import { responseNotification } from "../../../utils/notify";
import { useNavigate, useParams } from "react-router";
import BreadCrumb from "../../Layouts/Breadcrumb";
import RegistrationInfo from "./RegistrationInfo";
import OwnerInfo from "./OwnerInfo";
import BankAccountInfo from "./BankAccountInfo";
import ShopInfo from "./ShopInfo";
import WareHouseInfo from "./WareHouseInfo";
import moment from "moment";
const { Step } = Steps;

const AddMerchant = ({ merchantId }: any) => {
  const [current, setCurrent] = useState(0 || 0);
  const [regInfo, setRegInfo] = useState<any>();
  const [ownerInfo, setOwnerInfo] = useState<any>();
  const [bankAccountInfo, setBankAccountInfo] = useState<any>();
  const [shopInfo, setShopInfo] = useState<any>();
  const [wareHouseInfo, setWareHouseInfo] = useState<any>();

  const [merchantData, setMerchantData] = useState<any>({
    loading: false,
    data: null,
  });

  const route = useParams();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const fetchMerchantDetails = useCallback((getMerchantId: any) => {
    if (getMerchantId) {
      try {
        setMerchantData({ loading: true, data: null });
        axios
          .get(
            `${process.env.REACT_APP_RIDER_API}/merchant/details?merchantId=${getMerchantId}`,
            {
              headers: {
                Authorization: `Bearer ${authenticateToken()}`,
                "Content-Type": "application/json",
              },
            }
          )
          .then((data) => {
            if (data.status === 200) {
              setMerchantData({
                loading: false,
                data: data?.data?.merchant,
              });
            } else {
              setMerchantData({ loading: false, data: null });
              responseNotification(
                data.statusText || "something went wrong",
                "error"
              );
            }
          })
          .catch((err) => {
            setMerchantData({ loading: false, data: null });

            console.log(err);
          });
      } catch (error) {
        setMerchantData({ loading: false, data: null });
        console.log(error, "error");
      }
    }
  }, []);

  const onSubmit = async (data: any) => {
    setLoading(true);

    const readyData = data && {
      merchantRegistrationRequestRest: {
        merchantType: regInfo?.merchantType,
        password: regInfo?.password,
        serviceType: regInfo?.serviceType,
        mobileNumber: `+88${regInfo?.mobileNumber?.replace("+88", "")}`,
        // ...regInfo,
      },
      businessOwnerInfoAddRequestRest: {
        dateOfBirth: moment(ownerInfo.dateOfBirth).format("lll"),
        ...ownerInfo,
      },
      merchantBankAccountAddRequestRest: bankAccountInfo,
      storeDetailsAddRequestRest: {
        // ...shopInfo,
        authorizationMobileNumbers: [
          `+88${shopInfo?.authorizationMobileNumbers?.replace("+88", "")}`,
        ],
        businessHourFrom: shopInfo?.businessHourFrom,
        businessHourTo: shopInfo?.businessHourTo,
        businessType: shopInfo?.businessType,
        description: shopInfo?.description,
        storeLocation: shopInfo?.storeLocation,
        storeLogo: shopInfo?.storeLogo,
        storeBanner: shopInfo?.storeBanner,
        storeName: shopInfo?.storeName,
        type: shopInfo?.type,
        storeAddress: {
          address: shopInfo?.address,
          city: shopInfo?.city,
          district: shopInfo?.district,
          postcode: shopInfo?.postcode,
        },
      },

      wareHouse: {
        returnAddressIsSameAsStoreAddress:
          wareHouseInfo?.returnAddressIsSameAsStoreAddress || true,
        returnAddress: {
          address: wareHouseInfo?.returnAddress,
          city: wareHouseInfo?.returnCity,
          district: wareHouseInfo?.returnDistrict,
          postcode: wareHouseInfo?.returnPostcode,
        },
        returnLocation: wareHouseInfo?.wareHouseLocation,

        wareHouseAddressIsSameAsStoreAddress:
          wareHouseInfo?.wareHouseAddressIsSameAsStoreAddress || true,
        wareHouseAddress: {
          address: wareHouseInfo?.address,
          city: wareHouseInfo?.city,
          district: wareHouseInfo?.district,
          postcode: wareHouseInfo?.postcode,
        },
        wareHouseLocation: wareHouseInfo?.wareHouseLocation,
      },
    };

    if (merchantData.data) {
      await fetch(`${process.env.REACT_APP_RIDER_API}/admin/merchant/create`, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${authenticateToken()}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ...readyData,
          id: merchantData?.data?.id,
        }),
      })
        .then((res) => res.json())
        .then((res) => {
          setLoading(false);

          if (res.statusCode === 200) {
            responseNotification("Merchant Updated Successfully", "success");
            form.resetFields();
            navigate(-1);
          } else if (res.status === 500) {
            responseNotification("Internal server error", "error");
          } else {
            responseNotification(res.message || "something wrong", "warning");
          }
        })
        .catch((err) => {
          setLoading(false);
          responseNotification(`${"Internal server error"} ${err}`, "error");
          console.error("err", err);
        });
    } else {
      await fetch(`${process.env.REACT_APP_RIDER_API}/admin/merchant/create`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${authenticateToken()}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(readyData),
      })
        .then((res) => res.json())
        .then((res) => {
          setLoading(false);

          if (res.statusCode === 200) {
            responseNotification("Merchant Create Successfully", "success");
            form.resetFields();
            navigate(-1);
          } else if (res.status === 500) {
            responseNotification("Internal server error", "error");
          } else {
            responseNotification(res.message || "something wrong", "warning");
          }
        })
        .catch((err) => {
          setLoading(false);
          responseNotification(`${"Internal server error"} ${err}`, "error");
          console.error("err", err);
        });
    }
  };

  useEffect(() => {
    fetchMerchantDetails((route as any)?.merchantId);
  }, [fetchMerchantDetails, route]);

  useEffect(() => {
    fetchMerchantDetails(merchantId);
  }, [fetchMerchantDetails, merchantId]);

  useEffect(() => {
    if (merchantData.data) {
      form.resetFields(Object.keys(merchantData.data as any));
    }
  }, [form, merchantData]);

  const next = () => {
    setCurrent(current + 1);
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  useEffect(() => {
    if (regInfo) {
      next();
    }
  }, [regInfo, ownerInfo, bankAccountInfo, shopInfo, wareHouseInfo]);

  // console.log("regInfo:", regInfo);
  // console.log("OwnerInfo:", ownerInfo);
  // console.log("bankAccountInfo:", bankAccountInfo);
  // console.log("ShopInfo:", shopInfo);
  // console.log("wareHouseInfo:", shopInfo);

  const steps = [
    {
      title: "Register Info",
      content: (
        <Form
          onFinish={setRegInfo}
          form={form} // like ref
          layout="vertical"
          autoComplete="off"
        >
          <RegistrationInfo />
        </Form>
      ),
    },
    {
      title: "Owner Info",
      content: (
        <Form
          onFinish={setOwnerInfo}
          form={form} // like ref
          layout="vertical"
          autoComplete="off"
        >
          <OwnerInfo form={form} />
        </Form>
      ),
    },
    {
      title: "Bank Info",
      content: (
        <Form
          name="RegisterForm"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          onFinish={setBankAccountInfo}
          initialValues={{
            ...merchantData.data,
          }}
          form={form} // like ref
          layout="vertical"
          autoComplete="off"
        >
          <BankAccountInfo />
        </Form>
      ),
    },
    {
      title: "Store Info",
      content: (
        <Form
          onFinish={setShopInfo}
          form={form} // like ref
          layout="vertical"
          autoComplete="off"
        >
          <ShopInfo form={form} />
        </Form>
      ),
    },
    {
      title: "WareHouse Info",
      content: (
        <Form
          onFinish={setWareHouseInfo}
          form={form} // like ref
          layout="vertical"
          autoComplete="off"
        >
          <WareHouseInfo />
        </Form>
      ),
    },
    {
      title: "Overview",
      content: (
        <>
          <h4 className="font-bold text-xl mb-2">Merchant Overview</h4>
          <div className="">
            <dd>
              <Avatar src={ownerInfo?.profilePicture} />
              <dt>{ownerInfo?.name}</dt>
            </dd>
            <dd>
              <dt>{ownerInfo?.mobileNumber}</dt>
            </dd>
          </div>
        </>
      ),
    },
  ];

  return (
    <>
      <BreadCrumb title="Add Merchant" />
      <div className="content-body rounded-2xl">
        <div className="">
          <div className="bg-white p-4 mb-8">
            <Steps current={current}>
              {steps.map((item) => (
                <Step key={item.title} title={item.title} />
              ))}
            </Steps>
            <div className="steps-content bg-white shadow-lg shadow-gray-200 rounded-2xl p-4 my-6">
              {steps[current].content}
            </div>
            <div className="steps-action">
              {current > 0 && (
                <Button
                  style={{ margin: "0 8px" }}
                  onClick={() => prev()}
                  className="bg-yellow-500 text-white font-bold px-12"
                  shape="round"
                  size="large"
                >
                  Previous
                </Button>
              )}
              {current < steps.length - 1 && (
                <Button
                  type="primary"
                  className="bg-blue-600 font-bold px-12"
                  shape="round"
                  size="large"
                  onClick={() => {
                    // if (current === 0) {
                    //   form.validateFields();
                    //   form.submit();
                    // } else {
                    //   next();
                    // }
                    form.submit();
                  }}
                  // disabled={
                  //   (current === 1 && selectedProducts?.length === 0) ||
                  //   (type === "JC_MART" && !userHub)
                  // }
                >
                  Next
                </Button>
              )}
              {current === steps.length - 1 && (
                <Button
                  type="primary"
                  onClick={onSubmit}
                  className="font-bold px-12"
                  danger
                  shape="round"
                  size="large"
                >
                  Register Now
                </Button>
              )}
            </div>
          </div>{" "}
        </div>
      </div>
    </>
  );
};

export default AddMerchant;
