import { Checkbox, Form, Input, Divider, Row, Col } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { authenticateToken } from "../../../utils/auth";
import { responseNotification } from "../../../utils/notify";
import axios from "axios";
import { useSelector } from "react-redux";
import SubmitResetBtn from "../../common/SubmitBtn";
import Loader from "../../common/Loader";

const AddGroup = ({ visibleData, onCloseMethod }: any) => {
  const { type } = useSelector((state) => (state as any)?.authReducer);
  const [error, setError] = useState("");
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [groupData, setGroupData] = useState<any>(undefined);

  const [serviceOptions, setServiceOptions] = useState<any>({
    loading: false,
    list: [],
  });

  const onSubmit = async (data: any) => {
    setLoading(true);

    const readyData = data && {
      name: data.name,
      type: type || data.type,
      serviceActions: Object.values(checkedList)?.flatMap((arr: any) => {
        return arr?.map((item) => ({
          action: "all",
          serviceId: item?.value,
          serviceName: item?.label,
        }));
      }),
    };

    if (groupData) {
      await fetch(`${process.env.REACT_APP_AUTH_API}/group`, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${authenticateToken()}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ...readyData,
          id: groupData?.id,
          isActive: groupData?.isActive,
        }),
      })
        .then((res) => res.json())
        .then((res) => {
          setLoading(false);

          if (res.statusCode === 200) {
            responseNotification("Group Updated Successfully", "success");
            form.resetFields();
            if (onCloseMethod) {
              onCloseMethod();
            }
          } else if (res.status === 500) {
            responseNotification("Internal server error", "error");
          } else {
            responseNotification(res.message || "something wrong", "warning");
          }
        })
        .catch((err) => {
          setLoading(false);
          responseNotification(`${"Internal server error"} ${err}`, "error");
          console.error("err", err);
        });
    } else {
      await fetch(`${process.env.REACT_APP_AUTH_API}/group`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${authenticateToken()}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(readyData),
      })
        .then((res) => res.json())
        .then((res) => {
          setLoading(false);

          if (res.statusCode === 200) {
            responseNotification("Group Create Successfully", "success");
            form.resetFields();
            if (onCloseMethod) {
              onCloseMethod();
            }
          } else if (res.status === 500) {
            responseNotification("Internal server error", "error");
          } else {
            responseNotification(res.message || "something wrong", "warning");
          }
        })
        .catch((err) => {
          setLoading(false);
          responseNotification(`${"Internal server error"} ${err}`, "error");
          console.error("err", err);
        });
    }
  };

  const getServiceOptions = useCallback(
    async (key?: string) => {
      setServiceOptions({ loading: true, list: [] });
      const encodedUri = `${process.env.REACT_APP_AUTH_API}`;
      return axios
        .get(
          `${encodedUri}/service/by-type?Type=${type}&IsActive=true` +
            (key ? `&name=${key}` : ``),
          {
            headers: {
              Authorization: `Bearer ${authenticateToken()}`,
            },
          }
        )
        .then((res) => {
          setServiceOptions({
            loading: false,
            list: res.data?.services?.map(
              (service: { name: any; id: any }) => ({
                label: service.name,
                value: service.id,
              })
            ),
          });
        })
        .catch((err) => {
          setServiceOptions({ loading: false, list: [] });
          console.error("Services: Error", err);
        });
    },
    [type]
  );

  const getServicesByGroup = useCallback(async (groupId?: string) => {
    setLoading(true);
    const encodedUri = `${process.env.REACT_APP_AUTH_API}`;
    return axios
      .get(
        `${encodedUri}/service-group/services-by-group?groupId=${groupId}&serviceType=${type}`,
        {
          headers: {
            Authorization: `Bearer ${authenticateToken()}`,
          },
        }
      )
      .then((res: any) => {
        let temp = {} as any;

        temp = res?.data?.serviceGroups?.reduce((entryMap, { serviceName }) => {
          const key = serviceName?.name.split(" ").shift();
          entryMap[key] = entryMap[key]
            ? [
                ...entryMap[key],
                { label: serviceName?.name, value: serviceName?.id },
              ]
            : [{ label: serviceName?.name, value: serviceName?.id }];
          return entryMap;
        }, {});

        console.log(111, temp);
        setCheckedList(temp);
        setLoading(false);
      })
      .catch((err: any) => {
        setLoading(false);
        console.error("Services: Error", err);
      });
  }, []);

  // const onChange = (list: any) => {
  //   // console.log("list", list);
  //   // setCheckedList(list);
  //   // setIndeterminate(
  //   //   !!list.length && list.length < serviceOptions?.list?.length
  //   // );
  //   // setCheckAll(list.length === serviceOptions?.list?.length);
  //   try {
  //     const temp = {} as any;
  //     list?.forEach((id: any) => {
  //       if (checkedList?.[id]) {
  //         temp[id] = checkedList?.[id];
  //       } else {
  //         temp[id] = "all";
  //       }
  //     });

  //     setCheckedList(temp);
  //   } catch (e) {
  //     console.log(e);
  //   }
  // };

  useEffect(() => {
    if (visibleData) {
      setGroupData(visibleData);
      getServicesByGroup(visibleData?.id);
    }
  }, [getServicesByGroup, visibleData]);

  useEffect(() => {
    if (groupData) {
      form.resetFields(Object.keys(visibleData));
    }
  }, [form, groupData, visibleData]);

  useEffect(() => {
    getServiceOptions();
  }, [getServiceOptions]);

  //************************ */

  const groupedMap = serviceOptions?.list?.reduce((entryMap, e) => {
    const key = e.label.split(" ").shift();

    entryMap[key] = entryMap[key] ? [...entryMap[key], e] : [e];
    return entryMap;
  }, {});

  // const plainOptions = [...(groupedMap || [])].map(([_, group]) =>
  //   group?.map((service: any) => service.value)
  // );

  const isAllIndeterminate = (key?: string) => {
    if (key) {
      return (
        checkedList[key] &&
        Object.entries(checkedList[key]).length &&
        Object.entries(checkedList[key]).length <
          Object.entries(groupedMap[key]).length
      );
    } else {
      return (
        Object.entries(checkedList).filter(
          ([key, list]) => list?.length === groupedMap[key]?.length
        ).length &&
        Object.entries(checkedList).filter(
          ([key, list]) => list?.length === groupedMap[key]?.length
        )?.length < Object.entries(groupedMap)?.length
      );
    }
  };

  const isAllChecked = (key?: string) => {
    if (key) {
      return (
        checkedList[key] &&
        Object.entries(checkedList[key]).length &&
        Object.entries(checkedList[key]).length ===
          Object.entries(groupedMap[key]).length
      );
    } else {
      return (
        Object.entries(checkedList).filter(
          ([key, list]) => list?.length === groupedMap[key]?.length
        ).length &&
        Object.entries(checkedList).filter(
          ([key, list]) => list?.length === groupedMap[key]?.length
        )?.length === Object.entries(groupedMap)?.length
      );
    }
  };

  const [checkedList, setCheckedList] = useState<{ [key: string]: any[] }>({});

  const onChangeAction = (id: any, value: any) => {
    const temp = { ...checkedList };
    temp[id] = value;
    setCheckedList(temp);
  };

  const onChange = (list: any[], key?: string) => {
    if (key) {
      setCheckedList((prevVal) => {
        let tempObj = { ...(prevVal || {}) };
        if (list?.length) {
          tempObj[key] = list;
        } else {
          delete tempObj[key];
        }
        return tempObj;
      });
    } else {
      setCheckedList((prevVal) => {
        let tempObj = { ...(prevVal || {}) };
        list?.forEach((k) => {
          if (!tempObj[k]) {
            tempObj[k] = groupedMap[k];
          }
        });
        return tempObj;
      });
    }
  };

  const onCheckAllChange = (e, key?: string) => {
    setCheckedList((prevVal) => {
      let tempObj = { ...(prevVal || {}) };
      if (key)
        if (e.target.checked) tempObj[key] = groupedMap[key];
        else delete tempObj[key];
      else tempObj = e.target.checked ? groupedMap : {};
      return tempObj;
    });
  };

  // const onChangeGroup = (list: CheckboxValueType[], i: number) => {
  //   let prevList = [...checkedListForGroup];
  //   prevList[i] = list;
  //   setCheckedListForGroup(prevList);

  //   setIndeterminateGroup((prevData) => {
  //     const temp = [...(prevData || [])];
  //     temp[i] =
  //       !!prevList[i].length &&
  //       prevList[i].length < plainOptionsForGroup[i].length;

  //     return temp;
  //   });

  //   setCheckAllGroup((prevData) => {
  //     const temp = [...(prevData || [])];
  //     temp[i] = prevList[i].length === plainOptionsForGroup[i].length;
  //     return temp;
  //   });
  // };

  //************************** */

  console.log(
    "checkAllGroup",

    // selectedProductBarcode,
    // serviceOptions.list?.length,
    checkedList
  );
  // --------------------------------
  // --------------------------------

  return (
    <>
      <Form
        name="control-hooks"
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        className="ant-form ant-form-vertical"
        onFinish={onSubmit}
        form={form} // like ref
        layout="vertical"
        initialValues={{
          ...groupData,
        }}
      >
        <Form.Item
          hasFeedback
          label="Group Name"
          rules={[
            {
              required: true,
              message: "Name is Required!",
            },
          ]}
          name="name"
        >
          <Input id="name" type="text" placeholder="Enter Type Name" />
        </Form.Item>

        <>
          {serviceOptions?.loading ? (
            <Loader />
          ) : (
            <>
              <Checkbox
                indeterminate={isAllIndeterminate()}
                onChange={(e) => onCheckAllChange(e)}
                checked={isAllChecked()}
                className="mr-3"
                // disabled={!serviceOptions.list?.services?.length}
              >
                Check all
              </Checkbox>
              <Divider />
              <Checkbox.Group
                value={Object.keys(checkedList)}
                onChange={(list) => {
                  onChange(list);
                }}
              >
                <div className="">
                  {Object.entries(groupedMap)?.map(([key, value]: any) => (
                    <div key={key} className="mb-6">
                      <div className="w-full font-bold uppercase">
                        <Checkbox
                          indeterminate={isAllIndeterminate(key)}
                          onChange={(e) => onCheckAllChange(e, key)}
                          checked={isAllChecked(key)}
                          value={key}
                        >
                          {key}
                        </Checkbox>
                      </div>
                      <div className="w-full">
                        <Checkbox.Group
                          value={checkedList[key]?.map((item) => item?.value)}
                          onChange={(list) => {
                            onChange(
                              groupedMap[key]?.filter((item) =>
                                list?.includes(item?.value)
                              ),
                              key
                            );
                          }}
                          className="w-full"
                        >
                          <div className="w-full">
                            {value?.map((service, i) => (
                              <div className="w-full pl-6" key={service?.value}>
                                <Row>
                                  <Col span="24">
                                    <Checkbox
                                      // onClick={(e) => {
                                      //   e.preventDefault();
                                      //   e.stopPropagation();
                                      //   onChangeCheckbox(service?.value);
                                      // }}
                                      // checked={selectedProductBarcode?.find(
                                      //   (item: any) => serviceGroup?.value === item
                                      // )}
                                      // checked={!!checkedList?.[service.value]}
                                      // checked={checkAllGroup[i]}
                                      className="mr-3"
                                      value={service?.value}
                                    >
                                      {service?.label}
                                    </Checkbox>
                                  </Col>
                                  {/* <Col span="10">
                                    <Radio.Group
                                      onChange={(e) =>
                                        onChangeAction(
                                          service?.value,
                                          e.target?.value
                                        )
                                      }
                                      value={checkedList[service?.value]}
                                    >
                                      <Radio value={"all"}>All</Radio>
                                      <Radio value={"view"}>View</Radio>
                                    </Radio.Group>
                                  </Col> */}
                                </Row>
                              </div>
                            ))}
                          </div>
                        </Checkbox.Group>
                      </div>
                    </div>
                  ))}
                </div>
              </Checkbox.Group>
            </>
          )}
        </>

        <Divider />
        <SubmitResetBtn
          onClickReset={() => {
            form?.resetFields();
          }}
          disabled={loading || !!error}
          loading={loading}
        />
      </Form>
    </>
  );
};

export default AddGroup;
