import { authenticateToken } from "../../utils/auth";
import {
  CheckOutlined,
  CloseOutlined,
  DeleteOutlined,
  EditOutlined,
} from "@ant-design/icons";
import axios from "axios";
import { ReactElement, useCallback, useEffect, useState } from "react";
import { Avatar, Button, Drawer, Empty, Popconfirm, Switch } from "antd";
import BreadCrumb from "../Layouts/Breadcrumb";
import AddBtn from "../common/AddBtn";
import { responseNotification } from "../../utils/notify";
import Loader from "../common/Loader";
import Pagination from "../common/Pagination";
import { getPage, getParamValue } from "../../utils";
import { useLocation } from "react-router";
import styles from "../../styles/tailwind/List.module.css";
import { useSelector } from "react-redux";
import SingleAddOns from "./Details";
import AddAddOns from "./AddAddOns";
import moment from "moment";
import React from "react";
import { isAllowedService } from "../../utils/services";
const AddOnsList = (): ReactElement => {
  const { type } = useSelector((state) => (state as any)?.authReducer);
  const loc = useLocation();
  const page = getParamValue(loc.search, "page");
  const [limit, setLimit] = useState(16);
  const [status, setStatus] = useState("");
  const [showSearch, setShowSearch] = useState(true);
  const [visible, setVisible] = useState<any>(undefined);
  const [selectedAddOns, setSelectedAddOns] = useState(undefined);
  const [selectedAddOnsForEdit, setSelectedAddOnsForEdit] = useState(undefined);
  const [visiblePopconfirm, setVisiblePopconfirm] = useState(undefined);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const showPopconfirm = (id: any) => {
    setVisiblePopconfirm(id);
  };
  const [addOnsData, setAddOnsData] = useState<any>({
    loading: false,
    data: null,
  });

  const getAddOns = useCallback(async () => {
    setAddOnsData({ loading: true, data: null });

    const encodedUri = `${process.env.REACT_APP_CATALOG_READER_API}`;
    axios
      .get(
        `${encodedUri}/banner/by-status?type=${type}` +
          (status ? `&deleted=${status}` : ``),
        {
          headers: {
            Authorization: `Bearer ${authenticateToken()}`,
          },
        }
      )
      .then((res) => {
        setAddOnsData({ loading: false, data: res.data });
      })
      .catch((err) => {
        setAddOnsData({ loading: false, data: [] });
        console.error("AddOns: Error", err);
      });
  }, []);

  //
  const onDelete = async (id: any, val: any) => {
    if (id) {
      await fetch(`${process.env.REACT_APP_CATALOG_WRITER_API}/banner`, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${authenticateToken()}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          id: id,
          deleted: !val,
        }),
      })
        .then((res) => res.json())
        .then((res) => {
          if (res.statusCode === 200) {
            responseNotification("Status Updated Successfully", "success");
            getAddOns();
          } else if (res.status === 500) {
            responseNotification("Internal server error", "error");
          } else {
            responseNotification(res.message || "something wrong", "warning");
          }
        })
        .catch((err) => {
          responseNotification(`${"Internal server error"} ${err}`, "error");
          console.error("err", err);
        });
    }
  };
  const onBannerRemove = async (id: any) => {
    if (id) {
      await fetch(`${process.env.REACT_APP_CATALOG_WRITER_API}/banner`, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${authenticateToken()}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          id: id,
          deleted: true,
        }),
      })
        .then((res) => res.json())
        .then((res) => {
          if (res.statusCode === 200) {
            responseNotification("AddOns Remove Successfully", "success");
            getAddOns();
          } else if (res.status === 500) {
            responseNotification("Internal server error", "error");
          } else {
            responseNotification(res.message || "something wrong", "warning");
          }
        })
        .catch((err) => {
          responseNotification(`${"Internal server error"} ${err}`, "error");
          console.error("err", err);
        });
    }
  };
  useEffect(() => {
    getAddOns();
  }, [getAddOns]);

  const onClose = () => {
    getAddOns();
    setVisible(undefined);
    setSelectedAddOns(undefined);
    setSelectedAddOnsForEdit(undefined);
  };
  const handleCancel = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setVisiblePopconfirm(undefined);
  };

  return (
    <>
      <BreadCrumb
        title="AddOns List"
        subTitle={`${
          addOnsData?.data?.totalElements ||
          addOnsData?.data?.banners?.length ||
          0
        } AddOns(s)`}
        extra={[<AddBtn onClick={() => setVisible(true)} key={2} />]}
      />
      <div className={styles.contentWrapper}>
        <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
            <div
              className={
                showSearch ? `content-body-withSearch` : `content-body`
              }
            >
              {addOnsData?.loading ? (
                <Loader />
              ) : (
                <table className={styles.mainTable}>
                  <thead className="bg-white border-b">
                    <tr>
                      <th scope="col">AddOns</th>
                      <th scope="col">Status</th>
                      <th scope="col">Updated At</th>
                      <th scope="col">Updated By</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>

                  <tbody>
                    {addOnsData?.data?.banners?.length ? (
                      addOnsData?.data?.banners?.map(
                        (banner: any, index: any) => (
                          <tr
                            className="border-t hover:bg-gray-100"
                            key={index}
                            onClick={() => setSelectedAddOns(banner)}
                          >
                            <td>
                              <Avatar size={40} src={banner?.bannerImage} />{" "}
                              <span className="font-medium text-gray-500 ml-2">
                                {banner?.bannerName}
                              </span>
                            </td>
                            <td>
                              <Switch
                                checkedChildren={<CheckOutlined />}
                                unCheckedChildren={<CloseOutlined />}
                                defaultChecked={!banner?.deleted}
                                onChange={(val, e) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  onDelete(banner?.id, val);
                                }}
                              />
                            </td>
                            <td>
                              <small
                                className={`font-capitalize ${
                                  moment().diff(banner?.updatedAt, "minutes") >=
                                  60
                                    ? "text-red-600"
                                    : "text-gray-800"
                                }`}
                              >
                                {moment(banner?.updatedAt).fromNow()}
                              </small>
                              <br />
                              <span className="name">
                                {moment(banner?.updatedAt).format("lll")}
                              </span>
                            </td>
                            <td>
                              <span className="font-medium text-gray-500 ml-2">
                                {banner?.updatedBy?.name ||
                                  banner?.updatedBy?.mobileNumber}
                              </span>
                            </td>
                            <td>
                              <Button
                                shape="round"
                                type="dashed"
                                className="bg-white"
                                onClick={() => setSelectedAddOnsForEdit(banner)}
                              >
                                <EditOutlined />
                              </Button>
                              ,
                              <Popconfirm
                                placement="left"
                                title="Are you sure to remove?"
                                visible={banner?.id === visiblePopconfirm}
                                onConfirm={(e: any) => {
                                  console.log(true);
                                  e.preventDefault();
                                  e.stopPropagation();
                                  onBannerRemove(banner?.id);
                                }}
                                okButtonProps={{
                                  loading: confirmLoading,
                                  className: "bg-blue-400",
                                }}
                                onCancel={handleCancel}
                              >
                                <Button
                                  className="bg-white"
                                  type="dashed"
                                  danger
                                  shape="round"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    showPopconfirm(banner?.id);
                                  }}
                                >
                                  <DeleteOutlined />
                                </Button>
                              </Popconfirm>
                            </td>
                          </tr>
                        )
                      )
                    ) : (
                      <tr>
                        <td>
                          <Empty />
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>

        <Pagination
          {...addOnsData?.data}
          limit={limit}
          page={getPage(loc.search)}
        />
      </div>

      <Drawer
        title={
          visible
            ? "Add AddOns"
            : selectedAddOnsForEdit
            ? "Edit AddOns"
            : "AddOns Details"
        }
        placement="right"
        onClose={onClose}
        visible={visible || selectedAddOns || selectedAddOnsForEdit}
        width={450}
      >
        {visible || selectedAddOnsForEdit ? (
          <AddAddOns
            onCloseMethod={onClose}
            visibleData={selectedAddOnsForEdit}
          />
        ) : (
          <SingleAddOns AddOnsDetails={selectedAddOns} />
        )}
      </Drawer>
    </>
  );
};

export default AddOnsList;
