import { SearchOutlined, ShopOutlined } from "@ant-design/icons";

import {
  AutoComplete,
  Avatar,
  Button,
  Checkbox,
  Empty,
  Form,
  Input,
  Select,
  Spin,
  Tag,
  TreeSelect,
  TreeSelectProps,
} from "antd";
import {
  ReactElement,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";

import axios from "axios";
import { authenticateToken } from "../../../../utils/auth";
import { getImgUrl, getPage, getParamValue } from "../../../../utils/index";
import { responseNotification } from "../../../../utils/notify";
import { debounce } from "lodash";
import { useSelector } from "react-redux";
import React from "react";
import Pagination from "../../../common/Pagination";
import { useLocation } from "react-router-dom";
import styles from "../../../../styles/tailwind/List.module.css";
import LoaderFull from "../../../common/LoaderFull";
import Loader from "../../../common/Loader";
import { DefaultOptionType } from "antd/lib/cascader";

// @ts-ignore
import campaignProducts from "../../../../demo-files/campaign-products.csv";

type CSVData = {
  products: any[];
};

const CampaignProductList = ({
  onAssignModal,
  submitForm,
  onCloseMethod,
}: any): ReactElement => {
  const { type } = useSelector((state) => (state as any)?.authReducer);
  const [confirmLoading, setConfirmLoading] = useState(undefined);
  const loc = useLocation();
  const page = getParamValue(loc.search, "page");
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [fetchedCSVData, setFetchedCSVdata] = useState<CSVData | any>();

  // --------------------------------
  const [checkedList, setCheckedList] = React.useState([]);
  const [indeterminate, setIndeterminate] = React.useState(true);
  const [checkAll, setCheckAll] = React.useState(false);
  const [selectedProductBarcode, setSelectedProductBarcode] = useState<any>([]);
  // --------------------------------

  const simpleFileDownload = () => {
    window.location.href = `${campaignProducts}`;
  };
  // --------------------------------
  const { campaignInfo } = useSelector((state) => (state as any)?.authReducer);
  const campaignId = campaignInfo?.id;
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [status, setStatus] = useState("");
  const [limit, setLimit] = useState(50);
  const [minimumStock, setMinimumStock] = useState<any>(10000000);
  const [key, setKey] = useState("");
  const [barCode, setBarCode] = useState("");

  const [showSearch, setShowSearch] = useState(true);

  const [categoryId, setCategoryId] = useState<any>();
  const [brandId, setBrandId] = useState<any>();
  const [supplierId, setSupplierId] = useState<any>();

  const [shopId, setShopId] = useState<any>();
  const isDeleted = false;
  const isActive = true;
  // --------------------------------

  const [productBarcodeOptions, setProductBarcodeOptions] = useState<any>({
    list: [],
    loading: false,
  });

  const [productOptions, setProductOptions] = useState<any>({
    list: [],
    loading: false,
  });

  const [brandOptions, setBrandOptions] = useState({
    loading: false,
    list: [],
  });

  const [shopOptions, setShopOptions] = useState({
    loading: false,
    list: [],
  });

  const [treeData, setTreeData] = useState<Omit<DefaultOptionType, "label">[]>(
    []
  );

  const filteredCategory = treeData.find((category) => {
    return category?.id === categoryId;
  });

  const genTreeNode = (parentId: number, category: any) => {
    return {
      ...category,
      id: category?.id,
      pId: parentId,
      value: category?.id,
      title: category?.title,
      isLeaf: false,
    };
  };

  const getParentCategory = useCallback(async (val: string) => {
    const res = await axios.get(
      `${process.env.REACT_APP_CATALOG_READER_API}/admin/category/parent-by-type?type=${type}&page=0&limit=50` +
        (val ? `&key=${val}` : ``),
      {
        headers: {
          Authorization: `Bearer ${authenticateToken()}`,
        },
      }
    );
    setTreeData(
      res.data?.categories?.map(
        (category: Omit<DefaultOptionType, "label">) => ({
          ...category,
          id: category?.id,
          pId: 0,
          title: category?.title,
          value: category?.id,
          isLeaf: false,
        })
      )
    );
  }, []);

  const onLoadData: TreeSelectProps["loadData"] = ({ id }) => {
    return new Promise((resolve) => {
      const encodedUri = `${process.env.REACT_APP_CATALOG_READER_API}`;
      axios
        .get(
          `${encodedUri}/admin/category/child-category-by-parent?categoryId=${id}`,
          {
            headers: {
              Authorization: `Bearer ${authenticateToken()}`,
              "Content-Type": "application/json",
            },
          }
        )
        .then((data) => {
          if (data.status === 200) {
            // ****************************
            setTimeout(() => {
              setTreeData(
                treeData?.concat(
                  data?.data?.categories?.map(
                    (category: Omit<DefaultOptionType, "label">) =>
                      genTreeNode(id, category)
                  )
                )
              );
              resolve(undefined);
            }, 300);
            // ****************************
          } else {
            responseNotification(
              data.statusText || "something went wrong",
              "error"
            );
          }
        })
        .catch((err) => {
          console.log(err);
        });
    });
  };

  const [campaignProductData, setCampaignProductData] = useState<any>({
    loading: false,
    data: null,
  });

  const deleted = false;
  const [productMap, setProductMap] = useState<{ [key: string]: any }>({});
  const [productDataMap, setProductDataMap] = useState<{ [key: string]: any }>(
    {}
  );

  const reseAllFieldData = () => {
    setKey("");
    setBarCode("");
    setBrandId("");
    setShopId("");
    setCategoryId("");
    setSupplierId("");
    setMinimumStock(10000000);

    form.resetFields();
  };

  // **********************************
  // Search Options
  // **********************************

  const getBrandsOptions = useCallback(async (key?: string) => {
    setBrandOptions({ loading: true, list: [] });
    const encodedUri = `${process.env.REACT_APP_CATALOG_READER_API}`;
    const res = await axios.get(
      `${encodedUri}/brand/search?type=${type}&page=0&limit=20` +
        (key ? `&key=${key}` : ``),
      {
        headers: {
          Authorization: `Bearer ${authenticateToken()}`,
        },
      }
    );

    setBrandOptions({
      loading: false,
      list: res?.data?.brands?.map((brand: any) => ({
        label: brand.name,
        value: brand.id,
      })),
    });
  }, []);

  const getShopOptions = useCallback(
    async (key: any) => {
      setShopOptions({ loading: true, list: [] });
      const encodedUri = `${process.env.REACT_APP_CATALOG_READER_API}`;
      return axios
        .get(
          `${encodedUri}/shop/admin/search?type=${type}&page=0&limit=20` +
            (deleted ? `&deleted=${deleted}` : ``) +
            (key ? `&key=${key}` : ``),
          {
            headers: {
              Authorization: `Bearer ${authenticateToken()}`,
            },
          }
        )
        .then((res) => {
          setShopOptions({
            loading: false,
            list: res.data?.shops?.map((shop: any) => ({
              label: shop?.name,
              value: shop?.id,
            })),
          });
        })
        .catch((err) => {
          setShopOptions({ loading: false, list: [] });
          console.error("Categories: Error", err);
        });
    },
    [type, deleted]
  );

  const getProductByBarcodeOptions = useCallback(
    async (bCode: any) => {
      setProductBarcodeOptions({ loading: false, list: [] });

      setProductBarcodeOptions({ loading: true, list: [] });
      const encodedUri = `${process.env.REACT_APP_CATALOG_READER_API}`;

      const res = await axios.get(
        `${encodedUri}/admin/product/search?type=${type}&page=0&limit=20&deleted=${false}` +
          (bCode ? `&barCode=${bCode}` : ``) +
          (campaignId ? `&campaignId=${campaignId}` : ``) +
          (key ? `&key=${key}` : ``) +
          (isActive ? `&isActive=${isActive}` : ``) +
          (status ? `&status=${status}` : ``),
        {
          headers: {
            Authorization: `Bearer ${authenticateToken()}`,
          },
        }
      );
      setProductBarcodeOptions({
        loading: false,
        list: res?.data?.keys?.map((product: { name: any; barCode: any }) => {
          return {
            value: product?.barCode,
            label: product?.name,
          };
        }),
      });
    },
    [status, type, key, isActive, campaignId]
  );

  const getProductOptions = useCallback(
    async (key: any) => {
      setProductOptions({ loading: true, list: [] });
      const encodedUri = `${process.env.REACT_APP_CATALOG_READER_API}`;
      let url =
        `?type=${type}&page=0&limit=20` +
        (key ? `&key=${key}` : ``) +
        (isActive ? `&isActive=${isActive}` : ``);
      url = encodeURI(url);

      return axios
        .get(`${encodedUri}/admin/product/search${url}`, {
          headers: {
            Authorization: `Bearer ${authenticateToken()}`,
          },
        })
        .then((res) => {
          setProductOptions({
            loading: false,
            list: res.data?.keys?.map((product) => ({
              label: product.name,
              value: product.name,
            })),
          });
        })
        .catch((err) => {
          setProductOptions({ loading: false, list: [] });
          console.error("Products: Error", err);
        });
    },
    [isActive, type, key, page, limit]
  );

  const getCampaignProduct = useCallback(() => {
    if (campaignId) {
      try {
        setCampaignProductData({ loading: true, data: null });
        axios
          .get(
            `${
              process.env.REACT_APP_CATALOG_READER_API
            }/admin/product/v2?page=${page || 0}` +
              `&limit=${limit || 16}` +
              (type ? `&type=${type}` : ``) +
              (isDeleted ? `&isDeleted=${isDeleted}` : ``) +
              (isActive ? `&isActive=${isActive}` : ``) +
              (shopId ? `&shopId=${shopId}` : ``) +
              (brandId ? `&brandId=${brandId}` : ``) +
              (categoryId ? `&categoryId=${categoryId}` : ``) +
              (key ? `&key=${key}` : ``),
            {
              headers: {
                Authorization: `Bearer ${authenticateToken()}`,
                "Content-Type": "application/json",
              },
            }
          )
          .then((data) => {
            if (data.status === 200) {
              setCampaignProductData({
                loading: false,
                data: data?.data,
              });

              const tempProductMap = {};
              data?.data?.products?.forEach((p) => {
                if (p?.barCode)
                  tempProductMap[p?.barCode] = {
                    barCode: p?.barCode,
                    shopId: p?.shopId,
                    commission: 0,
                    stock: 0,
                    subsidy: 0,
                  };
              });
              setProductMap(tempProductMap);

              const tempProductDataMap = {};
              data?.data?.products?.forEach((p) => {
                if (p?.barCode) tempProductDataMap[p?.barCode] = p;
              });
              setProductDataMap(tempProductDataMap);

              setCheckedList(
                data.data.products.map(
                  (product: { barCode: any }) => product?.barCode
                )
              );
            } else {
              setCampaignProductData({ loading: false, data: null });
              responseNotification(
                data.statusText || "something went wrong",
                "error"
              );
            }
          })
          .catch((err) => {
            // setCampaignProductData({ loading: false, data: null });

            console.log(err);
          });
      } catch (error) {
        setCampaignProductData({ loading: false, data: null });

        console.log(error, "error");
      }
    }
  }, [
    limit,
    page,
    key,
    type,
    isDeleted,
    isActive,
    shopId,
    brandId,
    categoryId,
  ]);

  // **********************************
  // Search Options
  // **********************************

  const onChangeCategoey = (newValue: string) => {
    setCategoryId(newValue);
  };

  const onChange = (list: any) => {
    setSelectedProductBarcode(list);
    setIndeterminate(!!checkedList.length || checkedList.length <= list.length);
    setCheckAll(checkedList.length === list.length);
  };
  const onCheckAllChange = (e: any) => {
    setSelectedProductBarcode(e.target.checked ? checkedList : []);
    setIndeterminate(false);
    setCheckAll(e.target.checked);
  };

  const onSubmit = async () => {
    setConfirmLoading(true);
    await fetch(
      `${process.env.REACT_APP_CATALOG_WRITER_API}/campaign/add-products`,
      {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${authenticateToken()}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          barCodeShopIds: selectedProductBarcode.map(
            (barCode) => productMap[barCode]
          ),
          campaignId: campaignId,
        }),
      }
    )
      .then((res) => res.json())
      .then((res) => {
        setLoading(false);

        if (res.statusCode === 200) {
          responseNotification(
            "Campaign Product Assign Successfully",
            "success"
          );
          form.resetFields();
          if (onCloseMethod) {
            onCloseMethod();
          }
          setConfirmLoading(false);
        } else if (res.status === 500) {
          responseNotification("Internal server error", "error");
        } else {
          responseNotification(res.message || "something wrong", "warning");
        }
      })
      .catch((err) => {
        setLoading(false);
        responseNotification(`${"Internal server error"} ${err}`, "error");
        console.error("err", err);
      });
  };

  const fetchRef = useRef<any>(0);

  const handleSearch = React.useMemo<any>(() => {
    const loadOptions = (value: string, field: string) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;

      if (fetchId !== fetchRef.current) {
        return;
      }

      if (value) {
        if (field === "product") getProductOptions(value);
        else if (field === "productByBarcode")
          getProductByBarcodeOptions(value);
        else if (field === "brand") getBrandsOptions(value);
        else if (field === "shop") getShopOptions(value);
      }
    };

    return debounce(loadOptions, 800);
  }, [
    getBrandsOptions,
    getProductOptions,
    getProductByBarcodeOptions,
    getShopOptions,
  ]);

  useEffect(() => {
    if (submitForm) {
      // form.submit();
      onSubmit();
    }
  }, [submitForm]);

  console.log(submitForm);

  useEffect(() => {
    getProductOptions("");
    getCampaignProduct();
    getShopOptions("");
    getParentCategory(``);
    getBrandsOptions("");
  }, [
    getCampaignProduct,
    getBrandsOptions,
    getShopOptions,
    getProductOptions,
    getParentCategory,
    campaignId,
  ]);

  const resetData = () => {
    form?.resetFields();
    setFetchedCSVdata(null);
    setCategoryId(null);
  };

  const onClose = () => {
    getCampaignProduct();
    resetData();
    form?.resetFields();
    setDrawerVisible(false);
  };

  confirmLoading && <LoaderFull />;

  return (
    <React.Fragment>
      <>
        <section className="content-body rounded-2xl">
          <div className="bg-white shadow-sm shadow-gray-200 rounded-2xl px-2">
            <div className="sticky top-2 shadow-sm border border-slate-100 z-30 bg-white rounded-lg px-2 py-1 mb-4">
              {!onAssignModal && (
                <div className="flex justify-between items-center ">
                  <h3 className="text-xl text-gray-700 font-bold">
                    Product List
                    <span className="text-xs font-light ml-2">
                      {campaignProductData?.data?.totalElements ||
                        campaignProductData?.data?.length ||
                        0}
                      Product(s)
                    </span>
                  </h3>
                  <div className="d-flex items-center">
                    <Button
                      type="dashed"
                      shape="circle"
                      onClick={() => setShowSearch(!showSearch)}
                      key={1}
                    >
                      <SearchOutlined />
                    </Button>

                    <Button
                      type="primary"
                      danger={selectedProductBarcode?.length}
                      shape="round"
                      title="Buld Upload/Remove"
                      className="ml-2 bg-slate-100 border-0 text-slate-600"
                      onClick={() => setDrawerVisible(!drawerVisible)}
                    >
                      {selectedProductBarcode?.length
                        ? `Remove ${selectedProductBarcode?.length || 0} items`
                        : "Upload"}
                    </Button>
                  </div>
                </div>
              )}
              <div className="flex items-center justify-between w-full">
                {showSearch && (
                  <div className="flex py-1 mt-2 w-full">
                    <Form
                      layout="inline"
                      form={form}
                      className={styles.formInline}
                    >
                      <Form.Item name="search">
                        <AutoComplete
                          style={{ width: 300 }}
                          onSearch={(e) => handleSearch(e, "product")}
                          onSelect={(val: {
                            toString: () => SetStateAction<string>;
                          }) => setKey(val.toString())}
                          options={productOptions?.list}
                          defaultActiveFirstOption={false}
                          notFoundContent={
                            productOptions?.loading ? (
                              <Spin size="small" />
                            ) : null
                          }
                        >
                          <Input.Search
                            placeholder="Search by Name"
                            onSearch={(val) => setKey(val)}
                            enterButton
                            loading={productOptions.loading}
                          />
                        </AutoComplete>
                      </Form.Item>

                      <Form.Item name="shop_search">
                        <Select
                          allowClear
                          showSearch
                          // defaultValue={getShopName}
                          placeholder="Filter by Shop"
                          optionFilterProp="children"
                          onChange={(val) => setShopId(val)}
                          onSearch={(e) => handleSearch(e, "shop")}
                          filterOption={() => {
                            return true;
                          }}
                          options={shopOptions.list}
                        ></Select>
                      </Form.Item>
                      <Form.Item name="brand_search">
                        <Select
                          allowClear
                          showSearch
                          placeholder="Filter by Brand"
                          optionFilterProp="children"
                          onChange={(val) => setBrandId(val)}
                          onSearch={(e) => handleSearch(e, "brand")}
                          filterOption={() => {
                            return true;
                          }}
                          options={brandOptions?.list}
                        ></Select>
                      </Form.Item>
                      <Form.Item name="category_search">
                        <TreeSelect
                          allowClear
                          treeDataSimpleMode
                          style={{ width: "100%" }}
                          value={categoryId}
                          dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
                          placeholder="Filter by Category"
                          onChange={onChangeCategoey}
                          loadData={onLoadData}
                          treeData={treeData}
                        />
                      </Form.Item>
                    </Form>
                    <Button
                      type="primary"
                      size="large"
                      danger
                      onClick={reseAllFieldData}
                    >
                      Reset
                    </Button>
                  </div>
                )}

                {/* <Button
                  type="primary"
                  size="large"
                  ghost
                  onClick={() => onSubmit()}
                >
                  Submit
                </Button> */}
              </div>
            </div>

            <div className={styles.contentWrapper}>
              <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
                  <div
                    className={
                      showSearch ? `content-body-withSearch` : `content-body`
                    }
                  >
                    <Checkbox
                      indeterminate={indeterminate}
                      onChange={onCheckAllChange}
                      checked={
                        checkAll ||
                        selectedProductBarcode?.length === checkedList?.length
                      }
                      className="mr-3"
                    >
                      Check all
                    </Checkbox>
                    <Checkbox.Group
                      value={selectedProductBarcode}
                      onChange={onChange}
                    >
                      {confirmLoading && <LoaderFull />}
                      {campaignProductData?.loading ? (
                        <Loader />
                      ) : (
                        <table className={styles.mainTable}>
                          <thead className="bg-white border-b">
                            <tr>
                              <th scope="col"></th>
                              <th scope="col">Product</th>
                              <th scope="col">Price & Stock</th>
                              <th scope="col">Shop Info</th>
                              <th scope="col">Commission</th>
                              <th scope="col">Stock</th>
                              <th scope="col">Subsidy</th>
                            </tr>
                          </thead>

                          <tbody>
                            {Object.entries(productDataMap)?.length ? (
                              Object.entries(productDataMap)?.map(
                                (
                                  [barCode, product]: [
                                    barCode: string,
                                    product: any
                                  ],
                                  index: any
                                ) => (
                                  <tr
                                    className="border-t hover:bg-gray-100"
                                    key={index}
                                    // onClick={() =>
                                    //   navigate(
                                    //     `/products/${campaignProduct?.slug}/details`
                                    //   )
                                    // }
                                    onClick={(e) => {
                                      e.preventDefault();
                                      e.stopPropagation();
                                      onChange(product?.barCode);
                                    }}
                                  >
                                    <td>
                                      <Checkbox
                                        onClick={(e) => {
                                          e.preventDefault();
                                          // setCheckedList(list => {

                                          // })
                                        }}
                                        className="mr-3"
                                        value={barCode}
                                      />
                                    </td>
                                    <td>
                                      <Avatar
                                        size={45}
                                        src={getImgUrl(product?.productImage)}
                                        shape="square"
                                      />
                                      <span className="font-medium text-gray-500 ml-2">
                                        {product?.name?.slice(0, 50)}
                                      </span>
                                    </td>
                                    <td>
                                      <div className="flex_">
                                        {product?.variations?.length
                                          ? product?.variations?.map(
                                              (variant: any, index: string) => (
                                                <React.Fragment key={index}>
                                                  <div className="flex justify-content-start mb-1">
                                                    <Tag
                                                      color={
                                                        variant?.stock < 10
                                                          ? `red`
                                                          : `green`
                                                      }
                                                    >
                                                      Stock :{" "}
                                                      <b>
                                                        {variant?.stock || 0}
                                                      </b>
                                                    </Tag>

                                                    <Tag>
                                                      MRP{" "}
                                                      {
                                                        variant?.price
                                                          ?.currencyCode
                                                      }
                                                      {variant?.price
                                                        ?.discountedPrice ||
                                                        variant?.price?.mrp}
                                                      {variant?.price
                                                        ?.discountedPrice &&
                                                        variant?.price
                                                          ?.discountedPrice !==
                                                          variant?.price
                                                            ?.mrp && (
                                                          <span className="text-red-600 ml-1">
                                                            <del>
                                                              {variant?.price
                                                                ?.currencyCode ||
                                                                "Tk"}
                                                              {
                                                                variant?.price
                                                                  ?.mrp
                                                              }
                                                            </del>
                                                          </span>
                                                        )}
                                                    </Tag>
                                                    <Tag>
                                                      TP {variant?.price?.tp}{" "}
                                                      {
                                                        variant?.price
                                                          ?.currencyCode
                                                      }
                                                    </Tag>
                                                  </div>
                                                </React.Fragment>
                                              )
                                            )
                                          : undefined}
                                      </div>
                                    </td>

                                    <td>
                                      <ShopOutlined />
                                      <span className="name ml-1 text-uppercase">
                                        {product?.shop?.name}
                                      </span>
                                    </td>
                                    <td>
                                      <Input
                                        name="commission"
                                        type="number"
                                        min={0}
                                        placeholder="Enter Commission Amount"
                                        onChange={(e) => {
                                          setProductMap((prevVal) => {
                                            let temp = { ...(prevVal || {}) };
                                            temp[barCode] = {
                                              ...(temp[barCode] || {}),
                                              commission: parseFloat(
                                                e.target.value
                                              ),
                                            };
                                            return temp;
                                          });
                                        }}
                                        style={{ minWidth: 80 }}
                                      />
                                    </td>
                                    <td>
                                      <Input
                                        name="stock"
                                        type="number"
                                        min={0}
                                        placeholder="Enter Product Stock"
                                        onChange={(e) => {
                                          setProductMap((prevVal) => {
                                            let temp = { ...(prevVal || {}) };
                                            temp[barCode] = {
                                              ...(temp[barCode] || {}),
                                              stock: parseInt(e.target.value),
                                            };
                                            return temp;
                                          });
                                        }}
                                        style={{ minWidth: 80 }}
                                      />
                                    </td>
                                    <td>
                                      <Input
                                        name="subsidy"
                                        type="number"
                                        min={0}
                                        placeholder="Enter Product Subsidy"
                                        onChange={(e) => {
                                          setProductMap((prevVal) => {
                                            let temp = { ...(prevVal || {}) };
                                            temp[barCode] = {
                                              ...(temp[barCode] || {}),
                                              subsidy: parseFloat(
                                                e.target.value
                                              ),
                                            };
                                            return temp;
                                          });
                                        }}
                                        style={{ minWidth: 80 }}
                                      />
                                    </td>
                                  </tr>
                                )
                              )
                            ) : (
                              <tr>
                                <td>
                                  <Empty />
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      )}
                    </Checkbox.Group>
                  </div>
                </div>
              </div>

              <Pagination
                {...campaignProductData?.data}
                limit={limit}
                page={getPage(loc.search)}
              />
            </div>
          </div>
        </section>
      </>
    </React.Fragment>
  );
};

export default CampaignProductList;
