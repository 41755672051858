import {
  AutoComplete,
  Button,
  DatePicker,
  Empty,
  Form,
  Input,
  Modal,
  Select,
  Spin,
} from "antd";
import { SearchOutlined, SnippetsOutlined } from "@ant-design/icons";
import {
  ReactElement,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useLocation, useNavigate } from "react-router";
import axios from "axios";
import moment from "moment";
import { authenticateToken } from "../../utils/auth";
import BreadCrumb from "../Layouts/Breadcrumb";
import { getPage, getParamValue, OrderTypeArray } from "../../utils";
import Loader from "../common/Loader";
import { debounce } from "lodash";
import Pagination from "../common/Pagination";
import styles from "../../styles/tailwind/List.module.css";
import { useSelector } from "react-redux";
import SingleOrder from "./Details";
import { isAllowedService } from "../../utils/services";
import React from "react";
const { RangePicker } = DatePicker;

const OrderList = ({
  inShop = false,
  getShopId,
}: {
  inShop?: boolean;
  getShopId?: string;
}): ReactElement => {
  const { type } = useSelector((state) => (state as any)?.authReducer);
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const loc = useLocation();
  const page = getParamValue(loc.search, "page");
  const [limit, setLimit] = useState(16);
  const [status, setStatus] = useState("");
  const [orderId, setOrderId] = useState("");
  const [shopId, setShopId] = useState<any>();
  const [driverId, setDriverId] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");

  const [showSearch, setShowSearch] = useState(true);
  const [secondDrawer, setSecondDrawer] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState<any>();
  const [pmethodId, setPmethodId] = useState("");
  const [paymentMethod, setPaymentMethod] = useState<any>({
    loading: false,
    list: [],
  });
  console.log("payment method::", pmethodId);
  const [range, setRange] = useState<any>({
    from: null,
    to: null,
  });

  const [orderData, setOrderData] = useState<any>({
    loading: false,
    data: null,
  });

  const [orderOptions, setOrderOptions] = useState({
    loading: false,
    list: [],
  });

  const [shopOptions, setShopOptions] = useState({
    loading: false,
    list: [],
  });

  const [usersOptions, setUsersOptions] = useState({
    loading: false,
    list: [],
  });

  const [driversOptions, setDriversOptions] = useState({
    loading: false,
    list: [],
  });

  const onClose = () => {
    setSelectedOrder(undefined);
    setSecondDrawer(false);
    setSelectedOrder(undefined);
  };

  const getOrders = useCallback(async () => {
    const from = moment(range.from)?.toISOString();
    const to = moment(range.to)?.toISOString();
    setOrderData({ loading: true, data: null });

    const encodedUrl = `${process.env.REACT_APP_ORDER_API}`;
    axios
      .get(
        `${encodedUrl}/admin/order/v2?type=${type}` +
          (orderId ? `&orderIdOrBaseOrderId=${orderId}` : ``) +
          (mobileNumber ? `&mobileNumber=${mobileNumber}` : ``) +
          (shopId
            ? `&shopId=${shopId}`
            : getShopId
            ? `&shopId=${getShopId}`
            : ``) +
          (status ? `&status=${status}` : ``) +
          (pmethodId ? `&paymentMethod=${pmethodId}` : ``) +
          (from ? `&from=${from}` : ``) +
          (to ? `&to=${to}` : ``) +
          (page
            ? `&page=${
                page == orderData?.data?.currentPageNumber ? 0 : page || 0
              }`
            : ``) +
          (limit ? `&limit=${limit}` : ``),
        {
          headers: {
            Authorization: `Bearer ${authenticateToken()}`,
          },
        }
      )
      .then((res) => {
        setOrderData({ loading: false, data: res.data });
      })
      .catch((err) => {
        setOrderData({ loading: false, data: [] });
        console.error("Order: Error", err);
      });
  }, [limit, page, shopId, orderId, mobileNumber, status, pmethodId, range]);

  const getOrderOptions = useCallback(
    async (getOrderId: any) => {
      setOrderOptions({ loading: true, list: [] });
      const encodedUri = `${process.env.REACT_APP_ORDER_API}`;
      return axios
        .get(
          `${encodedUri}/admin/order/v2?page=${page || 0}` +
            (getOrderId ? `&orderIdOrBaseOrderId=${getOrderId}` : ``) +
            (type ? `&type=${type}` : ``) +
            (status ? `&status=${status}` : ``) +
            (limit ? `&limit=${limit}` : ``),
          {
            headers: {
              Authorization: `Bearer ${authenticateToken()}`,
            },
          }
        )
        .then((res) => {
          setOrderOptions({
            loading: false,
            list: res.data?.orders?.map(
              (order: { baseOrderId: string; orderId: string }) => ({
                label: `${order?.baseOrderId} || ${order?.orderId}`,
                value: order?.baseOrderId,
              })
            ),
          });
        })
        .catch((err) => {
          setOrderOptions({ loading: false, list: [] });
          console.error("Categories: Error", err);
        });
    },
    [limit, page, status, type]
  );

  const getShopOptions = useCallback(
    async (key: any) => {
      setShopOptions({ loading: true, list: [] });
      const encodedUri = `${process.env.REACT_APP_CATALOG_READER_API}`;
      return axios
        .get(
          `${encodedUri}/shop/admin/search?page=0&limit=20` +
            (key ? `&key=${key}` : ``) +
            (type ? `&type=${type}` : ``),
          {
            headers: {
              Authorization: `Bearer ${authenticateToken()}`,
            },
          }
        )
        .then((res) => {
          setShopOptions({
            loading: false,
            list: res.data?.shops?.map((shop: { id: any; name: any }) => ({
              value: shop.id,
              label: shop.name,
            })),
          });
        })
        .catch((err) => {
          setShopOptions({ loading: false, list: [] });
          console.error("Shops: Error", err);
        });
    },
    [type]
  );

  const getUsersOptions = useCallback(
    async (mobileNumber: any) => {
      setUsersOptions({ loading: true, list: [] });
      const encodedUri = `${process.env.REACT_APP_AUTH_API}`;
      axios
        .get(
          `${encodedUri}/admin/user?` +
            `page=${0}` +
            `&limit=${20}` +
            (status ? `&status=${status}` : ``) +
            (mobileNumber
              ? `&mobileNumber=%2B88${mobileNumber?.replace("+88", "")}`
              : ``),
          {
            headers: {
              Authorization: `Bearer ${authenticateToken()}`,
            },
          }
        )
        .then((res) => {
          setUsersOptions({
            loading: false,
            list: res.data?.users?.map(
              (user: { fullName: any; mobileNumber: string }) => ({
                label: user.fullName,
                value: user.mobileNumber.replace("+88", ""),
              })
            ),
          });
        })
        .catch((err) => {
          setUsersOptions({ loading: false, list: [] });
          console.error("Users: Error", err);
        });
    },
    [status]
  );

  const getDriversOptions = useCallback(
    async (mobileNumber: string) => {
      setDriversOptions({ loading: true, list: [] });
      const encodedUri = `${process.env.REACT_APP_RIDER_API}`;
      axios
        .get(
          `${encodedUri}/admin/driver?` +
            (mobileNumber
              ? `&mobileNumberOrName=${mobileNumber?.replace("+88", "")}`
              : ``) +
            (status ? `&status=${status}` : ``) +
            `&page=${0}` +
            `&limit=${20}`,
          {
            headers: {
              Authorization: `Bearer ${authenticateToken()}`,
            },
          }
        )
        .then((res) => {
          setDriversOptions({
            loading: false,
            list: res.data?.drivers?.map((driver: any) => ({
              label: `${driver?.mobileNumber.replace("+88", "")} - ${
                driver.name
              }`,
              value: driver?.id,
            })),
          });
        })
        .catch((err) => {
          setDriversOptions({ loading: false, list: [] });
          console.error("Drivers: Error", err);
        });
    },
    [status]
  );
  const fatchPaymentMethod = async () => {
    const data = await fetch(
      `${process.env.REACT_APP_PAY_API}/payment-method?` +
        (type ? `&type=${type}` : ``),
      {
        headers: {
          Authorization: `Bearer ${authenticateToken()}`,
          "Content-type": "application/json",
        },
      }
    );
    const response = await data.json();

    setPaymentMethod({
      loading: false,
      list: response?.methods?.map((method: { name: any }) => ({
        value: method.name,
        label: method.name,
      })),
    });
  };

  useEffect(() => {
    fatchPaymentMethod();
  }, []);

  const fetchRef = useRef(0);
  const handleSearch = useMemo(() => {
    const loadOptions = (value: string, field: string) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;

      if (fetchId !== fetchRef.current) {
        return;
      }
      if (value) {
        if (field === "order") getOrderOptions(value);
        if (field === "shop") getShopOptions(value);
        if (field === "user") getUsersOptions(value);
        if (field === "driver") getDriversOptions(value);
      }
    };

    return debounce(loadOptions, 800);
  }, [getOrderOptions, getUsersOptions, getDriversOptions]);

  useEffect(() => {
    getOrders();
  }, [getOrders]);

  useEffect(() => {
    if (showSearch) {
      getOrderOptions("");
      getShopOptions("");
    }
  }, [showSearch]);

  useEffect(() => {
    if (secondDrawer) {
      getDriversOptions("");
      setDriverId(``);
      // form.resetFields();
    }
  }, [secondDrawer, form]);

  const reseAllFieldData = () => {
    form?.resetFields();
    setStatus("");
    setOrderId("");
    setPaymentMethod("");
    setPmethodId("");
    setMobileNumber("");
    setShopId("");
    setRange({
      from: null,
      to: null,
    });
  };

  return (
    <>
      <BreadCrumb
        title="Order List"
        subTitle={`${orderData?.data?.totalElements} Order(s)`}
        childComponent={inShop ? true : false}
        extra={[
          <Button
            type="dashed"
            shape="circle"
            onClick={() => setShowSearch(!showSearch)}
            key={1}
          >
            <SearchOutlined />
          </Button>,
          isAllowedService(`Order Create`) && (
            <Button
              type="primary"
              danger
              shape="round"
              onClick={() => navigate(`/orders/create`)}
            >
              Manual Order
            </Button>
          ),
        ]}
      />
      {showSearch && (
        <div className={`${styles.searchBox} ${inShop ? `mx-0` : ``}`}>
          <Form form={form} layout="inline" className={styles.formInline}>
            <Form.Item name="baseOrderIdSearch" initialValue={orderId}>
              <AutoComplete
                backfill
                allowClear
                onSearch={(e) => handleSearch(e, "order")}
                onSelect={(val: any) => {
                  setOrderId(val);
                }}
                options={orderOptions?.list}
                defaultActiveFirstOption={false}
                notFoundContent={
                  orderOptions?.loading ? <Spin size="small" /> : null
                }
                dropdownMatchSelectWidth={335}
              >
                <Input.Search
                  placeholder="Order ID or Base Order ID"
                  onSearch={(val) => {
                    setOrderId(val);
                  }}
                  enterButton
                />
              </AutoComplete>
            </Form.Item>

            {/* <Form.Item name="orderId" initialValue={orderId}>
              <AutoComplete
                dropdownMatchSelectWidth={250}
                style={{ width: 250 }}
                onSearch={(e) => handleSearch(e, "order")}
                onSelect={(val: any) => {
                  setOrderId(val);
                }}
                options={orderOptions?.list}
                defaultActiveFirstOption={false}
                notFoundContent={
                  orderOptions?.loading ? <Spin size="small" /> : null
                }
              >
                <Input.Search
                  placeholder="Shop Order ID"
                  onSearch={(val) => {
                    setOrderId(val);
                  }}
                  enterButton
                /> 
              </AutoComplete>
            </Form.Item> */}

            <Form.Item name="phone_search" initialValue={mobileNumber}>
              <AutoComplete
                style={{ width: 250 }}
                onSearch={(e) => handleSearch(e, "user")}
                onSelect={(val: { toString: () => SetStateAction<string> }) =>
                  setMobileNumber(val.toString())
                }
                options={usersOptions?.list}
                defaultActiveFirstOption={false}
                notFoundContent={
                  usersOptions?.loading ? <Spin size="small" /> : null
                }
              >
                <Input.Search
                  placeholder="Search by User Phone"
                  onSearch={(val) => setMobileNumber(val)}
                  enterButton
                  loading={usersOptions.loading}
                  maxLength={11}
                  type="number"
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                />
              </AutoComplete>
            </Form.Item>

            {!inShop && (
              <Form.Item name="shop_search" initialValue={shopId}>
                <Select
                  allowClear
                  showSearch
                  placeholder="Filter by Shop"
                  optionFilterProp="children"
                  onChange={(val) => setShopId(val)}
                  onSearch={(e) => handleSearch(e, "shop")}
                  filterOption={(input, option) => {
                    console.log(input, option);
                    return true;
                  }}
                  options={shopOptions?.list}
                ></Select>
              </Form.Item>
            )}

            <Form.Item name="status_search" initialValue={status}>
              <Select
                showSearch
                placeholder="Filter by Status"
                onChange={(val) => setStatus(val)}
              >
                <Select.Option value="">ALL STATUS</Select.Option>
                {Object.values(OrderTypeArray)?.map((type, i) => (
                  <Select.Option value={type} key={i}>
                    {type?.split("_").join(" ")}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item name="payment_method">
              <Select
                allowClear
                placeholder="Filter by Payment Method"
                optionFilterProp="children"
                onChange={(val) => setPmethodId(val)}
                filterOption={() => {
                  return true;
                }}
                options={paymentMethod?.list}
              ></Select>
            </Form.Item>

            <Form.Item name="range">
              <RangePicker
                defaultValue={
                  range.from != null && range.to != null
                    ? [moment(range.from), moment(range.to)]
                    : null
                }
                onChange={(e, f) => {
                  console.log("momnet", moment.utc(f[0])?.toISOString());
                  setRange({ from: f?.[0], to: f?.[1] });
                }}
                showTime={{
                  hideDisabledOptions: true,
                  defaultValue: [
                    moment("00:00:00", "HH:mm:ss"),
                    moment("23:59:59", "HH:mm:ss"),
                  ],
                }}
                inputReadOnly
                allowClear
              />
            </Form.Item>
          </Form>
          <Button
            type="primary"
            danger
            size="large"
            htmlType="reset"
            onClick={reseAllFieldData}
          >
            Reset
          </Button>
        </div>
      )}

      <div className={styles.contentWrapper}>
        <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
            <div
              className={
                showSearch ? `content-body-withSearch` : `content-body`
              }
            >
              {orderData?.loading ? (
                <Loader />
              ) : (
                <table className={styles.mainTable}>
                  <thead className="bg-white border-b">
                    <tr>
                      <th scope="col">Order ID</th>
                      <th scope="col">Date</th>
                      {!inShop && <th scope="col">Shop</th>}
                      <th scope="col">Amount</th>
                      <th scope="col">Method</th>
                      <th scope="col">Status</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>

                  <tbody>
                    {orderData?.data?.orders?.length ? (
                      orderData?.data?.orders?.map((order: any, index: any) => (
                        <tr
                          className="border-t hover:bg-gray-100 cursor-pointer"
                          key={index}
                          onClick={() =>
                            navigate(
                              `${inShop ? `/shops/${getShopId}` : ``}/orders/${
                                order?.orderId
                              }/details`
                            )
                          }
                          // navigate(
                          //   `${
                          //     inShop ? `/shops/${getShopId}` : ``
                          //   }/products/${product?.slug}/details`
                          // )
                        >
                          <td>
                            <span className="font-medium text-slate-700">
                              {order?.orderId}
                            </span>
                            <br />
                            <span className="font-light text-slate-600">
                              {order?.baseOrderId}
                            </span>
                          </td>
                          <td>
                            <small
                              className={`font-capitalize ${
                                moment().diff(order?.createdAt, "minutes") >= 60
                                  ? "text-red-600"
                                  : "text-gray-800"
                              }`}
                            >
                              {moment(order?.createdAt).fromNow()}
                            </small>
                            <br />
                            <span className="name">
                              {moment(order?.createdAt).format("lll")}
                            </span>
                          </td>
                          {!inShop && (
                            <td>
                              <div className="flex">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="h-4 w-4 mr-1 text-gray-400"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke="currentColor"
                                  strokeWidth={2}
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"
                                  />
                                </svg>
                                {order?.shop?.name}
                              </div>
                            </td>
                          )}
                          <td>&#2547;{parseInt(order?.total)}</td>
                          <td>{order?.paymentMethod}</td>
                          <td>
                            <span className="border p-1 px-5 rounded-2xl bg-white">
                              {order?.status}
                            </span>
                          </td>
                          <td>
                            <Button
                              shape="round"
                              type="dashed"
                              className="px-6"
                              onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                setSelectedOrder(order);
                              }}
                            >
                              <SnippetsOutlined />
                            </Button>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td>
                          <Empty />
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>

        <Pagination
          {...orderData?.data}
          limit={limit}
          page={getPage(loc.search)}
        />
      </div>

      <Modal
        width={`80%`}
        title={`ORDER DETAILS - ${selectedOrder?.orderId}`}
        open={selectedOrder}
        onCancel={onClose}
        okButtonProps={{ type: "primary", danger: true }}
        footer={false}
      >
        <SingleOrder inShop={true} selectedOrder={selectedOrder} />
      </Modal>
    </>
  );
};

export default OrderList;
