import React from "react";
import AddOnsList from "../components/AddOns";
import MainLayout from "../components/Layouts";

const BannerPage = () => {
  return (
    <MainLayout>
      <AddOnsList />
    </MainLayout>
  );
};

export default BannerPage;
