import { Alert, Divider, Form, Input, Select, Switch } from "antd";
import { useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { authenticateToken } from "../../utils/auth";
import { responseNotification } from "../../utils/notify";
import ImageUploader from "../common/ImageUploader";
import SubmitResetBtn from "../common/SubmitBtn";
import styles from "../../styles/tailwind/Add.module.css";
import { SHOW_SEARCHBAR } from "../../redux/search/searchType";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCropAlt, faXmark } from "@fortawesome/free-solid-svg-icons";
import React from "react";
import TextArea from "antd/es/input/TextArea";
import { debounce } from "lodash";
import axios from "axios";
import { getParamValue } from "../../utils";
import { useLocation } from "react-router";

const AddAddOns = ({ visibleData, onCloseMethod }: any) => {
  const { type } = useSelector((state) => (state as any)?.authReducer);
  const [startUpload, setStartUpload] = useState<
    "Initiated" | "Uploading" | "Uploaded"
  >();
  const [showSearch, setShowSearch] = useState(true);
  const [generatedImgUrl, setGeneratedImgUrl] = useState<string>();
  const loc = useLocation();
  const page = getParamValue(Lock.search, "page");
  const [limit, setLimit] = useState(16);
  const [key, setKey] = useState("");
  const [error, setError] = useState("");
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [cropImage, setCropImage] = useState(true);
  const [addOnsData, setAddOnsData] = useState<any>(undefined);
  const [productId, setProductId] = useState<string>();
  const [productOptions, setProductOptions] = useState<any>({
    list: [],
    loading: false,
  });
  const getProductOptions = useCallback(
    async (key: any) => {
      setProductOptions({ loading: true, list: [] });
      const encodedUri = `${process.env.REACT_APP_CATALOG_READER_API}`;
      let url = `?type=${type}&page=0&limit=20` + (key ? `&key=${key}` : ``);
      url = encodeURI(url);

      return axios
        .get(`${encodedUri}/admin/product/search${url}`, {
          headers: {
            Authorization: `Bearer ${authenticateToken()}`,
          },
        })
        .then((res) => {
          setProductOptions({
            loading: false,
            list: res.data?.keys?.map((product: any) => ({
              label: product?.name,
              value: product?.id,
            })),
          });
        })
        .catch((err) => {
          setProductOptions({ loading: false, list: [] });
          console.error("Products: Error", err);
        });
    },
    [key, page, limit]
  );

  useEffect(() => {
    if (startUpload === "Uploaded") {
      form.submit();
    }
  }, [form, startUpload]);

  const onSubmit = async (data: any) => {
    if (generatedImgUrl) {
      setLoading(true);

      const readyData = data && {
        name: data.addOnsName,
        productId: data.productId,
        description: data.description,
        price: {
          discountedPrice: data.discountedPrice,
          mrp: data.mrpPrice,
          tp: data.tpPrice,
        },
        stock: data.stock,
        type: type,
        imageUrl: generatedImgUrl ? generatedImgUrl : data?.addOnsImage,
      };

      if (addOnsData) {
        await fetch(`${process.env.REACT_APP_CATALOG_WRITER_API}/add-on`, {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${authenticateToken()}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            ...readyData,
            id: addOnsData?.id,
            deleted: false,
          }),
        })
          .then((res) => res.json())
          .then((res) => {
            setLoading(false);

            if (res.statusCode === 200) {
              responseNotification("AddOns Updated Successfully", "success");
              form.resetFields();
              if (onCloseMethod) {
                onCloseMethod();
              }
            } else if (res.status === 500) {
              responseNotification("Internal server error", "error");
            } else {
              responseNotification(res.message || "something wrong", "warning");
            }
          })
          .catch((err) => {
            setLoading(false);
            responseNotification(`${"Internal server error"} ${err}`, "error");
            console.error("err", err);
          });
      } else {
        await fetch(`${process.env.REACT_APP_CATALOG_WRITER_API}/add-on`, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${authenticateToken()}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(readyData),
        })
          .then((res) => res.json())
          .then((res) => {
            setLoading(false);

            if (res.statusCode === 200) {
              responseNotification("AddOns Create Successfully", "success");
              form.resetFields();
              if (onCloseMethod) {
                onCloseMethod();
              }
            } else if (res.status === 500) {
              responseNotification("Internal server error", "error");
            } else {
              responseNotification(res.message || "something wrong", "warning");
            }
          })
          .catch((err) => {
            setLoading(false);
            responseNotification(`${"Internal server error"} ${err}`, "error");
            console.error("err", err);
          });
      }
    } else {
      responseNotification(`Image required`, "error");
    }
  };

  useEffect(() => {
    if (showSearch) {
      getProductOptions("");
    }
  }, [showSearch]);

  useEffect(() => {
    if (visibleData) {
      setAddOnsData(visibleData);
      setGeneratedImgUrl(visibleData?.addOnsImage);
    }
  }, [visibleData]);

  useEffect(() => {
    if (addOnsData) {
      form.resetFields(Object.keys(visibleData));
      setGeneratedImgUrl(visibleData?.addOnsImage);
    }
  }, [addOnsData, form, visibleData]);

  const fetchRef = useRef(0);
  const handleSearch = React.useMemo(() => {
    const loadOptions = (value: string, field: string) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;

      if (fetchId !== fetchRef.current) {
        return;
      }
      if (type) {
        if (value) {
          if (field === "product") getProductOptions(value);
        }
      } else {
        responseNotification("Select a type before search", "warning");
      }
    };

    return debounce(loadOptions, 800);
  }, [getProductOptions, type]);

  return (
    <>
      <Form
        name="control-hooks"
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        className={styles.formStyles}
        onFinish={onSubmit}
        form={form} // like ref
        layout="vertical"
        initialValues={{
          ...addOnsData,
        }}
      >
        <Form.Item
          hasFeedback
          label="Product"
          rules={[
            {
              required: true,
              message: "Product is required!",
            },
          ]}
          name="productId"
        >
          <Select
            showSearch
            placeholder="Select Product"
            optionFilterProp="children"
            onSearch={(val) => {
              handleSearch(val, "product");
            }}
            onChange={(val) => setProductId(val)}
            filterOption={() => {
              return true;
            }}
            options={productOptions?.list}
          ></Select>
        </Form.Item>

        <Form.Item
          hasFeedback
          label="Add Ons Name"
          rules={[
            {
              required: true,
              message: "Name is Required!",
            },
          ]}
          name="addOnsName"
        >
          <Input id="addOnsName" type="text" placeholder="Enter Add Ons Name" />
        </Form.Item>

        <Form.Item
          hasFeedback
          label="Description"
          name="description"
          style={{ minWidth: "100%" }}
        >
          <TextArea
            id="description"
            placeholder="Enter Add Ons Description"
            rows={4}
          />
        </Form.Item>
        <Form.Item
          hasFeedback
          label="Discounted Price"
          rules={[
            {
              required: true,
              message: "Discounted Price is Required!",
            },
          ]}
          name="discountedPrice"
        >
          <Input
            type="number"
            id="discountedPrice"
            min={0}
            placeholder="Enter Discounted Price"
          />
        </Form.Item>
        <Form.Item
          hasFeedback
          label="MRP"
          rules={[
            {
              required: true,
              message: "mrp is Required!",
            },
          ]}
          name="mrpPrice"
        >
          <Input type="number" id="mrpPrice" min={0} placeholder="Enter mrp" />
        </Form.Item>
        <Form.Item
          hasFeedback
          label="TP"
          rules={[
            {
              required: true,
              message: "TP is Required!",
            },
          ]}
          name="tpPrice"
        >
          <Input type="number" id="tpPrice" min={0} placeholder="Enter TP" />
        </Form.Item>
        <Form.Item
          hasFeedback
          label="Stock"
          rules={[
            {
              required: true,
              message: "Stock is Required!",
            },
          ]}
          name="stock"
        >
          <Input type="number" id="stock" min={0} placeholder="Enter Stock" />
        </Form.Item>

        <Divider orientation="left">Image</Divider>
        <div className="flex items-center pb-4">
          <ImageUploader
            imgUrl={generatedImgUrl || ""}
            startUpload={startUpload}
            setStartUpload={(val: any) => setStartUpload(val)}
            setGeneratedImgUrl={(url: any) => setGeneratedImgUrl(url)}
            // crop={cropImage ? 2.5 : 0}
          />
          <div className=" pr-8">
            <Switch
              onChange={() => setCropImage(!cropImage)}
              defaultChecked={cropImage}
              checkedChildren={<FontAwesomeIcon icon={faCropAlt} />}
              unCheckedChildren={<FontAwesomeIcon icon={faXmark} />}
            />
          </div>
        </div>

        {cropImage && (
          <Alert
            message="Image ratio must be 2.5 (1600/640 PX)"
            type="info"
            showIcon
            className="mb-4 rounded-md shadow-sm"
          />
        )}
        <SubmitResetBtn
          onClickSubmit={(e) => {
            e.preventDefault();
            setStartUpload("Uploading");
          }}
          onClickReset={() => {
            form?.resetFields();
            setGeneratedImgUrl(undefined);
            setStartUpload(undefined);
            // setBannerUrl(undefined);
          }}
          disabled={loading || !!error}
          loading={loading}
        />
      </Form>
    </>
  );
};

export default AddAddOns;
