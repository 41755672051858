import {
  faBiking,
  faBullhorn,
  faCar,
  faClipboardList,
  faClock,
  faClone,
  faCopy,
  faCube,
  faFilm,
  faGift,
  faGifts,
  faHandshake,
  faImages,
  faLaptop,
  faList,
  faListNumeric,
  faListUl,
  faMapLocationDot,
  faMapMarker,
  faShop,
  faShoppingBasket,
  faSitemap,
  faTicket,
  faTruck,
  faUpload,
  faUser,
  faUsers,
  faUserShield,
  faBell,
  faComments,
  faEnvelopeOpen,
  faAlignCenter,
  faAlignLeft,
  faPager,
  faDiagramNext,
  faHandsHelping,
  faComment,
  faCreditCard,
  faUserTie,
  faObjectUngroup,
} from "@fortawesome/free-solid-svg-icons";

import UserManagementPage from "../pages/administration";
import { ServiceType } from "../type/service";
import ServiceGroupPage from "../pages/administration/groups";
import UserServicePage from "../pages/administration/services";
import DeliveryHeroPage from "../pages/delivery";
import DashboardPage from "../pages";
import DriverDetailsPage from "../pages/delivery/drivers/details";
import OrderPage from "../pages/orders";
import ShoppingMallPage from "../pages/shopping-mall";
import ShoppingMallDetailsPage from "../pages/shopping-mall/details";
import ShopPage from "../pages/shops";
import ShopDetailsPage from "../pages/shops/details";
import ShopOrderPage from "../pages/shops/orders";
import ShopProductOutOfStockPage from "../pages/shops/products/out-of-stock";
import ShopBrandsPage from "../pages/shops/brands";
import BannerPage from "../pages/banners";
import AddOnsPage from "../pages/addons";
import ShopAddPage from "../pages/shops/add";
import ProductPage from "../pages/products";
import BrandPage from "../pages/brands/brand";
import BrandDetailsPage from "../pages/brands/details";
import CategoryPage from "../pages/categories";
import CategoryProductPage from "../pages/categories/categry-product";
import ProductDetailsPage from "../pages/products/details";
import ProductEditPage from "../pages/products/edit";
import ProductAddPage from "../pages/products/create";
import SingleOrderPage from "../pages/orders/details";
import CategoryDetailsPage from "../pages/categories/details";
import ShopProductPage from "../pages/shops/products";
import ShopProductDetailsPage from "../pages/shops/products/details";
import ShopCategoryPage from "../pages/shops/categories";
import ShopTagPage from "../pages/shops/tags";
import ShopOrderDetailsPage from "../pages/shops/orders/details";
import ShopProductAddPage from "../pages/shops/products/add";
import ShopProductBulkUploadPage from "../pages/shops/products/bulkupload";
import ShopProductBulkUpdatePage from "../pages/shops/products/buklupdate";
import ShopProductEditPage from "../pages/shops/products/edit";
import TimeSlotPage from "../pages/delivery/time-slot";
import VehicleTypePage from "../pages/delivery/vehicle-type";
import ServiceLocationPage from "../pages/delivery/service-location";
import ServiceZonePage from "../pages/delivery/service-zone";
import UserPage from "../pages/users";
import UserDetailsPage from "../pages/users/details";
import TagPage from "../pages/tags";
import CampaignPage from "../pages/promotions/campaign";
import CampaignBrandPage from "../pages/promotions/campaign/brand";
import CampaignProductPage from "../pages/promotions/campaign/products/index";
import CampaignShopsPage from "../pages/promotions/campaign/shops";
import GlobalProductPage from "../pages/global-product";
import GlobalProductDetailsPage from "../pages/global-product/details";
import GlobalProductAddPage from "../pages/global-product/create";
import GlobalProductEditPage from "../pages/global-product/edit";
import CampaignDetailsPage from "../pages/promotions/campaign/details";
import OrderCreatePage from "../pages/orders/create";
import MyTransactionPage from "../pages/reports/transactions";
import MySettlementPage from "../pages/reports/my-settlements";
import VRHistoryPage from "../pages/reports/vr-history";
import DeliveryReportPage from "../pages/reports/delivery-report";
import RevenueReportPage from "../pages/reports/revenue-report";
import VatTaxDeliveryReportPage from "../pages/reports/delivery-vat-tax";
import SalesReportPage from "../pages/reports/sales-report";
import OrderVatTaxPage from "../pages/reports/order-vat-tax";
import MerchantPage from "../pages/merchants";
import MerchantProductPage from "../pages/merchants/products";
import MerchantShopPage from "../pages/merchants/shops";
import MerchantDetailsPage from "../pages/merchants/details";
import ThirdLevelCategoryPage from "../pages/categories/third-level";
import SecondLevelCategoryPage from "../pages/categories/sub-categories";
import PromoCodePage from "../pages/promotions/campaign/promo-codes";
import SMSNotificationPage from "../pages/notifications/sms";
import EmailNotificationPage from "../pages/notifications/email";
import PushNotificationPage from "../pages/notifications/index";
import FileUploaderPage from "../pages/file-uploader";
import RequestedProductPage from "../pages/requested-products";
import AdvertisePage from "../pages/promotions/campaign/advertise";
import DriverOrdersPage from "../pages/delivery/drivers/orders";
import DriverTransactionPage from "../pages/delivery/drivers/transactions";
import DriversCashCollectPage from "../pages/delivery/drivers/cash-collect";
import DriverUnsettleOrderPage from "../pages/delivery/drivers/unsettle-orders";
import DriverLocationHistoryPage from "../pages/delivery/drivers/location-history";
import ContentCategoryPage from "../pages/content/pages";
import ContentPage from "../pages/content";
import ContentDetailsPage from "../pages/content/details";
import ContentAddPage from "../pages/content/add";
import MerchantAddPage from "../pages/merchants/add";
import NeighborhoodPage from "../pages/neighborhood";
import NeighborhoodAddPage from "../pages/neighborhood/neighborhoodAdd";
import NeighborhoodDetailsPage from "../pages/neighborhood/detailsViewNighborhood";
import ViewAllNeighborhoodPage from "../pages/neighborhood/viewAllNeighborhood";
import CorporatePage from "../pages/corporates";
import CorporateDetailsPage from "../pages/corporates/details";
import CorporateEmployeeGroupPage from "../pages/corporates/employee-group";
import CorporateEmployeepage from "../pages/corporates/employees";
import CorporateTopupHistoryPage from "../pages/corporates/topup";
import FeedbackPage from "../pages/feedback";
import FeedbackTypePage from "../pages/feedback/types";
import FeedbackDetailsPage from "../pages/feedback/details";
import PaymentMethodPage from "../pages/payment-method";

import KeywordPage from "../pages/keywords";
import PopularKeywordPage from "../pages/keywords/popular";
import ProductKeywordPage from "../pages/keywords/product-keywords";
import ContentCategoryAddPage from "../pages/content/pages/add";
import ContentCategoryDetailsPage from "../pages/content/pages/details";
import MerchantRecruiterPage from "../pages/merchants/recruiters";
import MerchantTypePage from "../pages/merchants/types";

export const isAllowedService = (name: string) => {
  const services = localStorage.getItem("services");

  if (services) {
    const listfromApi: ServiceType[] = JSON.parse(services);
    const data = listfromApi.find((itemfromApi) => {
      return itemfromApi.serviceName === name;
    });
    if (data) {
      return true;
    }
  }
  return false;
};

export const allowedActions = (name: string) => {
  const services = localStorage.getItem("services");

  if (services) {
    const list: ServiceType[] = JSON.parse(services);
    const data = list.filter((item) => item.serviceName === name);
    if (data.length > 0) {
      data.map((d) => d.action);
    }
  }
  return [];
};

export const isEditableService = (name: string) => {
  const services = localStorage.getItem("services");

  if (services) {
    const list: ServiceType[] = JSON.parse(services);
    const data = list.find(
      (item) => item.serviceName === name && item.action === "all"
    );

    if (data) {
      return true;
    }
  }
  return false;
};

export const getAllServices = () => {
  const services = localStorage.getItem("services");
  if (services) {
    return JSON.parse(services);
  }
  return [];
};

type AdminService = {
  name: string;
  path?: string;
  icon?: any;
  element?: any;
  sub?: AdminService[];
  isParent?: boolean;
};

export const adminServices: AdminService[] = [
  {
    name: "Dashboard",
    path: "/",
    icon: faLaptop,
    element: DashboardPage,
  },

  // Orders Routes
  {
    name: "Orders",
    path: "/orders",
    icon: faCopy,
    sub: [
      {
        name: "List",
        path: "/",
        icon: faCopy,
        element: OrderPage,
        isParent: true,
      },
      {
        name: "Details",
        path: "/:orderId/details",
        element: SingleOrderPage,
      },
      {
        name: "Manual Order",
        path: "/create",
        element: OrderCreatePage,
      },
      {
        name: "Extra",
      },
    ],
  },

  // Merchant Routes
  {
    name: "Merchants",
    path: "/merchants",
    icon: faHandshake,
    sub: [
      {
        name: "All Merchant",
        path: "/",
        icon: faUsers,
        element: MerchantPage,
        // isParent: true,
      },
      {
        name: "Create",
        path: "/create",
        element: MerchantAddPage,
      },
      {
        name: "Edit",
        path: "/:merchantId/edit",
        element: MerchantAddPage,
      },
      {
        name: "Details",
        path: "/:merchantId/details",
        element: MerchantDetailsPage,
      },
      {
        name: "Shops",
        path: "/:merchantId/shops",
        element: MerchantShopPage,
      },
      {
        name: "Products",
        path: "/:merchantId/products",
        element: MerchantProductPage,
      },
      {
        name: "Recruiter",
        path: "/recruiters",
        icon: faUserTie,
        element: MerchantRecruiterPage,
      },
      {
        name: "Type",
        path: "/types",
        icon: faObjectUngroup,
        element: MerchantTypePage,
      },
    ],
  },
  {
    name: "Neighborhood",
    path: "/neighborhood",
    icon: faLaptop,
    sub: [
      {
        name: "Neighborhood All",
        path: "/",
        icon: faLaptop,
        element: NeighborhoodPage,
        isParent: true,
      },
      {
        name: "Neighborhood Add",
        path: "/add",
        element: NeighborhoodAddPage,
      },

      {
        name: "Neighborhood Details",
        path: "/:neighborhoodId/:shopType/details",
        element: NeighborhoodDetailsPage,
      },
      {
        name: "Neighborhood All",
        path: "/details",
        element: ViewAllNeighborhoodPage,
      },
    ],
  },
  // Shopping Mall Routes
  {
    name: "Shopping-Mall",
    path: "/shopping-mall",
    icon: faCube,
    sub: [
      {
        name: "All",
        path: "/",
        icon: faCube,
        element: ShoppingMallPage,
        isParent: true,
      },
      {
        name: "Details",
        path: "/:mallId/details",
        element: ShoppingMallDetailsPage,
      },
    ],
  },

  // Shops Routes
  {
    name: "Shops",
    path: "/shops",
    icon: faShop,
    sub: [
      {
        name: "All",
        path: "/",
        icon: faShop,
        element: ShopPage,
        isParent: true,
      },
      {
        name: "Create",
        path: "/create",
        element: ShopAddPage,
      },
      {
        name: "Edit",
        path: "/:shopId/edit",
        element: ShopAddPage,
      },
      {
        name: "Delete",
        path: "/:shopId/delete",
      },
      {
        name: "Details",
        path: "/:shopId/details",
        element: ShopDetailsPage,
      },
      {
        name: "Orders",
        path: "/:shopId/orders",
        element: ShopOrderPage,
      },
      {
        name: "Orders Details",
        path: "/:shopId/orders/:orderId/details",
        element: ShopOrderDetailsPage,
      },
      {
        name: "Out-Of-Stock",
        path: "/:shopId/out-of-stock",
        element: ShopProductOutOfStockPage,
      },
      {
        name: "Brands",
        path: "/:shopId/brands",
        element: ShopBrandsPage,
      },
      {
        name: "Products",
        path: "/:shopId/products",
        element: ShopProductPage,
      },
      {
        name: "Product Details",
        path: "/:shopId/products/:slug/details",
        element: ShopProductDetailsPage,
      },
      {
        name: "Product Add",
        path: "/:shopId/products/create",
        element: ShopProductAddPage,
      },
      {
        name: "Product Bulkupload",
        path: "/:shopId/products/bulkupload",
        element: ShopProductBulkUploadPage,
      },
      {
        name: "Product Bulkupdate",
        path: "/:shopId/products/bulkupdate",
        element: ShopProductBulkUpdatePage,
      },
      {
        name: "Product Edit",
        path: "/:shopId/products/:slug/edit",
        element: ShopProductEditPage,
      },
      {
        name: "Categories",
        path: "/:shopId/categories",
        element: ShopCategoryPage,
      },
      {
        name: "Tags",
        path: "/:shopId/tags",
        element: ShopTagPage,
      },
    ],
  },
  // Corporate Routes

  {
    name: "Corporate",
    path: "/corporates",
    icon: faLaptop,
    sub: [
      {
        name: "Corporate List",
        path: "/",
        icon: faHandsHelping,
        element: CorporatePage,
        isParent: true,
      },
      {
        name: "Details",
        path: "/:corporateId/details",
        element: CorporateDetailsPage,
      },
      {
        name: "Employee-Group",
        path: "/:corporateId/employee-group",
        element: CorporateEmployeeGroupPage,
      },
      {
        name: "Employees",
        path: "/:corporateId/employees",
        element: CorporateEmployeepage,
      },
      {
        name: "Topup-History",
        path: "/:corporateId/topup",
        element: CorporateTopupHistoryPage,
      },
    ],
  },

  // Categories Routes
  {
    name: "Categories",
    path: "/categories",
    icon: faSitemap,
    sub: [
      {
        name: "Categories",
        path: "/",
        icon: faListUl,
        element: CategoryPage,
      },
      {
        name: "Sub Category",
        path: "/level=2",
        icon: faListNumeric,
        element: SecondLevelCategoryPage,
      },
      {
        name: "Child Category",
        path: "/level=3",
        icon: faListNumeric,
        element: ThirdLevelCategoryPage,
      },
      {
        name: "Delete",
        path: "/:shopId/delete",
      },
      {
        name: "Details",
        path: "/:categoryId",
        element: CategoryDetailsPage,
      },
      {
        name: "Products",
        path: "/:categoryId/products",
        element: CategoryProductPage,
      },
    ],
  },
  // Products Routes
  {
    name: "Products",
    path: "/products",
    icon: faShoppingBasket,
    sub: [
      {
        name: "All",
        path: "/",
        icon: faShoppingBasket,
        element: ProductPage,
        isParent: true,
      },
      {
        name: "Create",
        path: "/create",
        element: ProductAddPage,
      },
      {
        name: "Edit",
        path: "/:slug/edit",
        element: ProductEditPage,
      },
      {
        name: "Details",
        path: "/:slug/details",
        element: ProductDetailsPage,
      },
    ],
  },

  // Global Products Routes
  {
    name: "Product Catalogue",
    path: "/global-product",
    icon: faClone,
    sub: [
      {
        name: "All",
        path: "/",
        icon: faClone,
        element: GlobalProductPage,
        isParent: true,
      },
      {
        name: "Details",
        path: "/:slug/details",
        element: GlobalProductDetailsPage,
      },
      {
        name: "Add",
        path: "/create",
        element: GlobalProductAddPage,
      },
      {
        name: "Edit",
        path: "/:slug/edit",
        element: GlobalProductEditPage,
      },
    ],
  },

  // Requested Products Routes
  {
    name: "Requested Product",
    path: "/requested-product",
    icon: faClone,
    sub: [
      {
        name: "All",
        path: "/",
        icon: faClone,
        element: RequestedProductPage,
        isParent: true,
      },
    ],
  },
  {
    name: "Banners",
    path: "/banners",
    icon: faImages,
    element: BannerPage,
  },
  {
    name: "Add Ons",
    path: "/addons",
    icon: faImages,
    element: AddOnsPage,
  },
  //Brands Routes
  {
    name: "Brands",
    path: "/brands",
    icon: faCube,
    sub: [
      {
        name: "All",
        path: "/",
        icon: faCube,
        element: BrandPage,
        isParent: true,
      },
      {
        name: "Details",
        path: "/:brandId/details",
        element: BrandDetailsPage,
      },
      {
        name: "Delete",
        path: "/:brandId/delete",
      },
    ],
  },

  // Content Tag Routes

  {
    name: "Tags",
    path: "/tags",
    icon: faImages,
    element: TagPage,
  },

  // Promotions Routes
  {
    name: "Promotion",
    path: "/promotion",
    icon: faGift,
    sub: [
      {
        name: "Campaign",
        path: "/campaign",
        icon: faBullhorn,
        element: CampaignPage,
      },
      {
        name: "Campaign Details",
        path: "/campaign/:campaignId",
        element: CampaignDetailsPage,
      },
      {
        name: "Campaign Products",
        path: "/campaign/:campaignId/products",
        element: CampaignProductPage,
      },
      {
        name: "Campaign Brands",
        path: "/campaign/:campaignId/brands",
        element: CampaignBrandPage,
      },
      {
        name: "Campaign Shops",
        path: "/campaign/:campaignId/shops",
        element: CampaignShopsPage,
      },
      {
        name: "Promo Code",
        path: "/promo-code",
        icon: faTicket,
        element: PromoCodePage,
      },
      {
        name: "Gifts",
        path: "/gifts",
        icon: faGifts,
        element: CampaignPage,
      },
      {
        name: "Advertise",
        path: "/advertise",
        icon: faFilm,
        element: AdvertisePage,
      },
    ],
  },

  // Delivery Routes
  {
    name: "Delivery",
    path: "/delivery",
    icon: faTruck,
    sub: [
      {
        name: "Drivers",
        path: "/drivers",
        icon: faBiking,
        element: DeliveryHeroPage,
        isParent: true,
      },
      {
        name: "Drivers Details",
        path: "/drivers/:id/details",
        element: DriverDetailsPage,
      },
      {
        name: "Orders",
        path: "/drivers/:id/orders",
        element: DriverOrdersPage,
      },
      {
        name: "Transactions",
        path: "/drivers/:id/transactions",
        element: DriverTransactionPage,
      },
      {
        name: "Cash Collect",
        path: "/drivers/:id/cash-collect",
        element: DriversCashCollectPage,
      },
      {
        name: "Unsettle Order",
        path: "/drivers/:id/unsettle-order",
        element: DriverUnsettleOrderPage,
      },
      {
        name: "Location History",
        path: "/drivers/:id/location-history",
        element: DriverLocationHistoryPage,
      },
      {
        name: "Time Slot",
        path: "/time-slot",
        icon: faClock,
        element: TimeSlotPage,
      },
      {
        name: "Vehicle Type",
        path: "/vehicle-type",
        icon: faCar,
        element: VehicleTypePage,
      },
      {
        name: "Service Zone",
        path: "/service-zone",
        icon: faMapMarker,
        element: ServiceZonePage,
      },
      {
        name: "Delete",
        path: "/:zoneId/delete",
      },
      {
        name: "Service Location",
        path: "/service-location",
        icon: faMapLocationDot,
        element: ServiceLocationPage,
      },
    ],
  },

  // Reports Routes
  {
    name: "Reports",
    path: "/reports",
    icon: faList,
    sub: [
      {
        name: "My Transactions",
        path: "/transactions",
        icon: faAlignLeft,
        element: MyTransactionPage,
      },
      {
        name: "Settlement Request",
        path: "/my-settlement",
        icon: faBell,
        element: MySettlementPage,
      },
      {
        name: "VR History",
        path: "/vr-history",
        icon: faAlignCenter,
        element: VRHistoryPage,
      },
      {
        name: "Delivery Report",
        path: "/delivery-report",
        icon: faAlignCenter,
        element: DeliveryReportPage,
      },
      {
        name: "Sales Report",
        path: "/sales-report",
        icon: faAlignCenter,
        element: SalesReportPage,
      },
      {
        name: "Revenue Report",
        path: "/revenue-report",
        icon: faAlignCenter,
        element: RevenueReportPage,
      },
      {
        name: "Order Vat-Tax",
        path: "/order-vat-tax",
        icon: faAlignCenter,
        element: OrderVatTaxPage,
      },
      {
        name: "Delivery Vat-Tax",
        path: "/delivery-vat-tax-report",
        icon: faAlignCenter,
        element: VatTaxDeliveryReportPage,
      },
    ],
  },

  // Notifications Routes
  {
    name: "Notifications",
    path: "/notifications",
    icon: faBell,
    sub: [
      {
        name: "SMS",
        path: "/sms",
        icon: faComments,
        element: SMSNotificationPage,
      },
      {
        name: "Email",
        path: "/email",
        icon: faEnvelopeOpen,
        element: EmailNotificationPage,
      },
      {
        name: "Push Notifications",
        path: "/push",
        icon: faBell,
        element: PushNotificationPage,
      },
    ],
  },
  //Keyword Routes
  {
    name: "Keywords",
    path: "/keywords",
    icon: faAlignCenter,
    sub: [
      {
        name: "All Keywords",
        path: "/all",
        icon: faAlignLeft,
        element: KeywordPage,
      },
      {
        name: "Popular",
        path: "/popular",
        icon: faAlignLeft,
        element: PopularKeywordPage,
      },
      {
        name: "Product",
        path: "/product",
        icon: faAlignLeft,
        element: ProductKeywordPage,
      },
    ],
  },
  // Content category Routes
  {
    name: "Contents",
    path: "/contents",
    icon: faPager,
    sub: [
      {
        name: "Pages",
        path: "/pages",
        icon: faDiagramNext,
        element: ContentCategoryPage,
      },

      {
        name: "Page Details",
        path: "/pages/:slug",
        element: ContentCategoryDetailsPage,
      },

      {
        name: "Page Create",
        path: "/pages/create",
        element: ContentCategoryAddPage,
      },
      {
        name: "Page Edit",
        path: "/pages/:slug/edit",
        element: ContentCategoryAddPage,
      },

      {
        name: "Articles",
        path: "/",
        icon: faListUl,
        element: ContentPage,
      },
      {
        name: "Details",
        path: "/:slug",
        element: ContentDetailsPage,
      },

      {
        name: "Create",
        path: "/create",
        element: ContentAddPage,
      },
      {
        name: "Edit",
        path: "/:slug/edit",
        element: ContentAddPage,
      },
    ],
  },

  // Administration Routes
  {
    name: "Administration",
    path: "/administration",
    icon: faUserShield,
    sub: [
      {
        name: "User Management",
        icon: faUser,
        path: "/admins",
        element: UserManagementPage,
      },
      {
        name: "User Groups",
        icon: faUsers,
        path: "/groups",
        element: ServiceGroupPage,
      },
      {
        name: "User Services",
        icon: faList,
        path: "/services",
        element: UserServicePage,
      },
    ],
  },

  // Users Routes
  {
    name: "Customer",
    path: "/customer",
    icon: faUsers,
    element: UserPage,
    sub: [
      {
        name: "All",
        path: "/",
        icon: faUsers,
        element: UserPage,
        isParent: true,
      },
      {
        name: "Details",
        path: "/:userId/details",
        element: UserDetailsPage,
      },
    ],
  },
  {
    name: "Feedbacks",
    path: "/feedbacks",
    icon: faComments,
    sub: [
      {
        name: "Feedback List",
        path: "/all",
        icon: faComment,
        element: FeedbackPage,
      },
      {
        name: "Feedback Type",
        path: "/type",
        icon: faAlignLeft,
        element: FeedbackTypePage,
      },
      {
        name: "Details",
        path: "/:feedbackId",
        icon: faAlignLeft,
        element: FeedbackDetailsPage,
      },
    ],
  },
  {
    name: "Payment Methods",
    path: "/payment-methods",
    icon: faCreditCard,
    element: PaymentMethodPage,
  },
  {
    name: "File Uploader",
    path: "/file-uploader",
    icon: faUpload,
    element: FileUploaderPage,
  },
];

export const getAllServicesList = () => {
  return adminServices?.flatMap((service) => {
    if (service.sub && service.sub.length) {
      return service.sub.map((sub) => `${service.name} ${sub.name}`);
    } else {
      return service.name;
    }
  });
};

export const getServicePaths = (path: string | undefined) => {
  if (path) {
    const parentService = adminServices?.find(
      (service) => service.path === path
    );
    if (parentService) {
      return parentService?.path || "";
    } else {
      const parentService = adminServices?.find((service) =>
        service.sub?.find((sub) => `${service.path}${sub.path}` === path)
      );
      return parentService?.path || "";
    }
  }
  return "";
};
