import { authenticateToken } from "../../utils/auth";
import {
  BarcodeOutlined,
  CheckOutlined,
  CloseOutlined,
  CopyOutlined,
  DownloadOutlined,
  EditOutlined,
  EyeOutlined,
  FileDoneOutlined,
  SearchOutlined,
  SwapOutlined,
} from "@ant-design/icons";
import axios from "axios";
import React, {
  ReactElement,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  Alert,
  AutoComplete,
  Avatar,
  Button,
  Checkbox,
  Drawer,
  Empty,
  Form,
  Input,
  Modal,
  Radio,
  Rate,
  Select,
  Spin,
  Switch,
  TreeSelect,
  TreeSelectProps,
  DatePicker,
} from "antd";
import BreadCrumb from "../Layouts/Breadcrumb";
import AddBtn from "../common/AddBtn";
import { responseNotification } from "../../utils/notify";
import Loader from "../common/Loader";
import Pagination from "../common/Pagination";
import { getPage, getParamValue, jcTypeArray } from "../../utils";
import { useLocation, useNavigate } from "react-router";
import styles from "../../styles/tailwind/List.module.css";
import { useSelector } from "react-redux";
import LoaderFull from "../common/LoaderFull";
import { debounce } from "lodash";
import { DefaultOptionType } from "antd/lib/select";
import QuickUpdate from "../Product/QuickUpdate";
import { Link } from "react-router-dom";
import moment from "moment";
import { isAllowedService } from "../../utils/services";
import { CSVLink } from "react-csv";
const { RangePicker } = DatePicker;
const { Option } = Select;

const GlobalProductList = (): ReactElement => {
  const router = useLocation();
  const { type, token } = useSelector((state) => (state as any)?.authReducer);
  const [serviceType, setServiceType] = useState(type);
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const loc = useLocation();
  const page = getParamValue(loc.search, "page");
  const [limit, setLimit] = useState(16);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [key, setKey] = useState("");
  const [categoryId, setCategoryId] = useState<any>();
  const [brandId, setBrandId] = useState<any>();
  const [isActive, setIsActive] = useState<any>(undefined);
  const [showSearch, setShowSearch] = useState(true);
  const [selectedProductForEdit, setSelectedProductForEdit] =
    useState<any>(undefined);

  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [shopId, setShopId] = useState<any>();
  const [merchantId, setMerchantId] = useState<any>();
  const [barCode, setBarCode] = useState(
    getParamValue(router.search, "barCode")
  );
  // --------------------------------
  const [checkedList, setCheckedList] = useState([]);
  const [indeterminate, setIndeterminate] = useState(true);
  const [checkAll, setCheckAll] = useState(false);
  const [selectedRadioBtn, setSelectedRadioBtn] = useState<any>(undefined);
  const [targetCategoryId, setTargetCategoryId] = useState<any>();
  const [selectedProductBarcode, setSelectedProductBarcode] = useState<any>([]);
  const [productBarcodeOptions, setProductBarcodeOptions] = useState<any>({
    list: [],
    loading: false,
  });
  const [globalProductDataForDownload, setGlobalProductDataForDownload] =
    useState<any>({
      loading: false,
      data: null,
    });
  // --------------------------------

  const [productData, setProductData] = useState<any>({
    loading: false,
    data: null,
  });
  const [range, setRange] = useState<any>({
    from: null,
    to: null,
  });
  const [productOptions, setProductOptions] = useState({
    loading: false,
    list: [],
  });

  const [brandOptions, setBrandOptions] = useState({
    loading: false,
    list: [],
  });

  const [shopsOptions, setShopsOptions] = useState<any>({
    list: [],
    loading: false,
  });

  const [merchantOptions, setMerchantOptions] = useState({
    loading: false,
    list: [],
  });

  const getProducts = useCallback(async () => {
    const from = moment(range.from)?.toISOString();
    const to = moment(range.to)?.toISOString();
    setProductData({ loading: true, data: null });

    const encodedUrl = `${process.env.REACT_APP_CATALOG_READER_API}`;
    axios
      .get(
        `${encodedUrl}/global-product?type=${serviceType}` +
          (categoryId ? `&categoryId=${categoryId}` : ``) +
          (brandId ? `&brandId=${brandId}` : ``) +
          (barCode ? `&barCode=${barCode}` : ``) +
          (key ? `&key=${key}` : ``) +
          (from ? `&startDate=${from}` : ``) +
          (to ? `&endDate=${to}` : ``) +
          (isActive ? `&isActive=${isActive}` : ``) +
          (page
            ? `&page=${
                page == productData.data?.currentPageNumber ? 0 : page || 0
              }`
            : ``) +
          (limit ? `&limit=${limit}` : ``),
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setProductData({ loading: false, data: res.data });

        setCheckedList(
          res.data.products.map((product: any) => product?.barCode)
        );
      })
      .catch((err) => {
        setProductData({ loading: false, data: [] });
        console.error("Type: Error", err);
      });
  }, [
    serviceType,
    categoryId,
    brandId,
    barCode,
    range,
    isActive,
    key,
    page,
    limit,
  ]);

  const getProductByBarcodeOptions = useCallback(
    async (bCode: any) => {
      setProductBarcodeOptions({ loading: false, list: [] });

      setProductBarcodeOptions({ loading: true, list: [] });
      const encodedUri = `${process.env.REACT_APP_CATALOG_READER_API}`;

      const res = await axios.get(
        `${encodedUri}/global-product?` +
          `page=${page || 0}` +
          `&limit=${limit || 16}` +
          // `&isDeleted=${false}` +
          (bCode ? `&barCode=${bCode}` : ``) +
          (key ? `&key=${key}` : ``) +
          (type ? `&type=${type}` : ``) +
          (isActive !== "" ? `&isActive=${isActive}` : ``),
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setProductBarcodeOptions({
        loading: false,
        list: res?.data?.keys?.map((product: { name: any; barCode: any }) => {
          return {
            value: product?.barCode,
            label: product?.name,
          };
        }),
      });
    },
    [page, limit, key, type, isActive, status]
  );

  const getProductOptions = useCallback(
    async (key: any) => {
      setProductOptions({ loading: true, list: [] });
      const encodedUri = `${process.env.REACT_APP_CATALOG_READER_API}`;
      return axios
        .get(
          `${encodedUri}/global-product?type=${serviceType}&page=0&limit=20` +
            (key ? `&key=${key}` : ``),
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          setProductOptions({
            loading: false,
            list: res.data?.products?.map((product: { name: any }) => ({
              label: product.name,
              value: product.name,
            })),
          });
        })
        .catch((err) => {
          setProductOptions({ loading: false, list: [] });
          console.error("Products: Error", err);
        });
    },
    [serviceType]
  );

  const getBrandsOptions = useCallback(async (key?: string) => {
    setBrandOptions({ loading: true, list: [] });
    const encodedUri = `${process.env.REACT_APP_CATALOG_READER_API}`;
    const res = await axios.get(
      `${encodedUri}/brand/search?type=${type}&page=0&limit=20` +
        (key ? `&key=${key}` : ``),
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    setBrandOptions({
      loading: false,
      list: res?.data?.brands?.map((brand: any) => ({
        label: brand.name,
        value: brand.id,
      })),
    });
  }, []);

  // ************************
  const [treeData, setTreeData] = useState<Omit<DefaultOptionType, "label">[]>(
    []
  );

  const genTreeNode = (parentId: number, category: any) => {
    return {
      ...category,
      id: category?.id,
      pId: parentId,
      value: category?.id,
      title: category?.title,
      isLeaf: false,
    };
  };

  const getParentCategory = useCallback(async (val: string) => {
    const res = await axios.get(
      `${process.env.REACT_APP_CATALOG_READER_API}/admin/category/parent-by-type?type=${type}&page=0&limit=50` +
        (val ? `&key=${val}` : ``),
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    setTreeData(
      res.data?.categories?.map(
        (category: Omit<DefaultOptionType, "label">) => ({
          ...category,
          id: category?.id,
          pId: 0,
          title: category?.title,
          value: category?.id,
          isLeaf: false,
        })
      )
    );
  }, []);

  const onLoadData: TreeSelectProps["loadData"] = ({ id }) => {
    return new Promise((resolve) => {
      const encodedUri = `${process.env.REACT_APP_CATALOG_READER_API}`;
      axios
        .get(
          `${encodedUri}/admin/category/child-category-by-parent?categoryId=${id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        )
        .then((data) => {
          if (data.status === 200) {
            // ****************************
            setTimeout(() => {
              setTreeData(
                treeData?.concat(
                  data?.data?.categories?.map(
                    (category: Omit<DefaultOptionType, "label">) =>
                      genTreeNode(id, category)
                  )
                )
              );
              resolve(undefined);
            }, 300);
            // ****************************
          } else {
            responseNotification(
              data.statusText || "something went wrong",
              "error"
            );
          }
        })
        .catch((err) => {
          console.log(err);
        });
    });
  };

  const onChange = (newValue: string) => {
    setCategoryId(newValue);
    setTargetCategoryId(newValue);
  };
  // ************************

  const getMerchantsOptions = useCallback(
    async (key?: string) => {
      setMerchantOptions({ loading: true, list: [] });
      const encodedUri = `${process.env.REACT_APP_RIDER_API}`;
      const res = await axios.get(
        `${encodedUri}/admin/merchant/all?page=0&limit=20` +
          (serviceType ? `&serviceType=${serviceType}` : ``) +
          (key ? `&nameOrMobileNumber=${key}` : ``),
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setMerchantOptions({
        loading: false,
        list: res.data?.merchants?.map((merchant: any) => ({
          label: `${
            merchant?.fullName?.toUpperCase() ||
            merchant?.merchantPersonalDetail?.name?.toUpperCase()
          } - ${merchant?.mobileNumber?.replace("+88", "")}`,
          value: merchant?.merchantId,
        })),
      });
    },
    [serviceType]
  );

  const getShopsOptions = useCallback(
    async (val?: string) => {
      setShopsOptions({ loading: true, list: [] });

      const res = await axios.get(
        `${process.env.REACT_APP_CATALOG_READER_API}/admin/shop${
          merchantId ? "/by-merchant" : ``
        }?page=0&limit=20` +
          (merchantId ? `&merchantId=${merchantId}` : ``) +
          (val ? `&key=${val}` : ``) +
          (type ? `&type=${type}` : ``),
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setShopsOptions({
        loading: false,
        list: res?.data?.shops?.map((shop: any) => {
          return {
            value: shop?.id,
            label: shop?.name,
          };
        }),
      });
    },
    [type, merchantId]
  );

  const onChangeCheckbox = (e: any) => {
    setSelectedProductBarcode((prevVal: any) => {
      const newVal = e;
      let newArr = Array.from(prevVal);
      const found = prevVal.find((item: any) => item === newVal);
      if (!found) {
        newArr.push(newVal);
      } else {
        newArr = newArr.filter((item) => item !== newVal);
      }
      return newArr;
    });

    setIndeterminate(
      !!checkedList.length &&
        checkedList.length <= selectedProductBarcode.length
    );
    setCheckAll(checkedList.length === selectedProductBarcode.length);
  };

  // const filteredCategory = treeData.find((category) => {
  //   return category?.id === categoryId;
  // });
  // ************************

  //
  const onDelete = async (id: string, barCode: string, val: boolean) => {
    setConfirmLoading(true);
    if (id) {
      await fetch(
        `${process.env.REACT_APP_CATALOG_WRITER_API}/global-product`,
        {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            barCode: barCode,
            id: id,
            deleted: !val,
          }),
        }
      )
        .then((res) => res.json())
        .then((res) => {
          if (res.statusCode === 200) {
            responseNotification("Status Updated Successfully", "success");
            getProducts();
            setConfirmLoading(false);
          } else if (res.status === 500) {
            responseNotification("Internal server error", "error");
          } else {
            responseNotification(res.message || "something wrong", "warning");
          }
        })
        .catch((err) => {
          responseNotification(`${"Internal server error"} ${err}`, "error");
          console.error("err", err);
        });
    }
  };

  const fetchRef = useRef(0);
  const handleSearch = useMemo(() => {
    const loadOptions = (value: string, field: string) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;

      if (fetchId !== fetchRef.current) {
        return;
      }
      if (value) {
        if (field === "product") getProductOptions(value);
        else if (field === "brand") getBrandsOptions(value);
        else if (field === "productByBarcode")
          getProductByBarcodeOptions(value);
        else if (field === "shop") getShopsOptions(value);
        else if (field === "merchant") getMerchantsOptions(value);
      }
    };

    return debounce(loadOptions, 800);
  }, [
    getProductOptions,
    getBrandsOptions,
    getProductByBarcodeOptions,
    getShopsOptions,
    getMerchantsOptions,
  ]);

  useEffect(() => {
    getProducts();
  }, [getProducts]);

  useEffect(() => {
    if (showSearch) {
      getProductOptions("");
      getParentCategory(``);
      getBrandsOptions("");
      getProductByBarcodeOptions("");
    }
  }, [showSearch]);

  useEffect(() => {
    if (visible) {
      getParentCategory(``);
      getMerchantsOptions();
      getShopsOptions();
      getProductByBarcodeOptions("");
      setSelectedRadioBtn("CLONE_PRODUCT");
    }
  }, [visible]);

  useEffect(() => {
    if (merchantId) {
      getShopsOptions();
    }
  }, [getShopsOptions, merchantId]);

  const reseAllFieldData = () => {
    form.resetFields();
    setKey("");
    setCategoryId("");
    setBrandId("");
    setBarCode("");
    setRange({
      from: null,
      to: null,
    });
    setIsActive(undefined);
  };

  // ----------------------------
  // ----------------------------

  //
  const onSubmit = async (data: any) => {
    setLoading(true);
    if (selectedRadioBtn === "CLONE_PRODUCT") {
      const readyData = data && {
        barCodes: selectedProductBarcode,
        shopIds: shopId,
        // supplierId: supplierId,
      };

      await fetch(
        `${process.env.REACT_APP_CATALOG_WRITER_API}/product/add-from-global-product`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(readyData),
        }
      )
        .then((res) => res.json())
        .then((res) => {
          setLoading(false);

          if (res.statusCode === 200) {
            responseNotification(
              "Product Cloned Successfully in Selected Shop(s)",
              "success"
            );
            // getShopsOptions();
            info({
              alreadyExists: res?.alreadyExists,
              newAdded: res?.newAdded,
              list: res?.alreadyExistsProductName,
            });

            setVisible(!visible);
            form.resetFields();
          } else if (res.status === 500) {
            responseNotification("Internal server error", "error");
          } else {
            responseNotification(res.message || "something wrong", "warning");
          }
        })
        .catch((err) => {
          setLoading(false);
          responseNotification(`${"Internal server error"} ${err}`, "error");
          console.error("err", err);
        });
    } else {
      const readyData = data && {
        barCodes: data.barCodes || selectedProductBarcode,
        categoryId: targetCategoryId,
      };

      await fetch(
        `${process.env.REACT_APP_CATALOG_WRITER_API}/global-product/category-update`,
        {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(readyData),
        }
      )
        .then((res) => res.json())
        .then((res) => {
          setLoading(false);

          if (res.statusCode === 200) {
            responseNotification("Category Changed Successfully", "success");
            getProducts();
            setVisible(!visible);
            form.resetFields();
          } else if (res.status === 500) {
            responseNotification("Internal server error", "error");
          } else {
            responseNotification(res.message || "something wrong", "warning");
          }
        })
        .catch((err) => {
          setLoading(false);
          responseNotification(`${"Internal server error"} ${err}`, "error");
          console.error("err", err);
        });
    }
  };

  const onCheckAllChange = (e: any) => {
    setSelectedProductBarcode(e.target.checked ? checkedList : []);
    setIndeterminate(false);
    setCheckAll(e.target.checked);
  };

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    form.resetFields();
    setVisible(false);
    setSelectedRadioBtn(undefined);
    setSelectedProductForEdit(undefined);
  };

  function info(alreadyExistsData: {
    alreadyExists: any;
    newAdded: any;
    list: any;
  }) {
    Modal.info({
      title: `Successfully added ${alreadyExistsData?.newAdded} item(s) and already exists ${alreadyExistsData?.alreadyExists} item(s)`,
      content: (
        <ul>
          {alreadyExistsData?.list?.map(
            (alreadyExistsProductName: any, i: any) => (
              <>
                <small>
                  {i + 1}. {alreadyExistsProductName}
                </small>
                <br />
              </>
            )
          )}
        </ul>
      ),
      width: 600,
      onOk() {},
      okText: "GOT IT",
    });
  }
  // ----------------------------
  const getGlobalProductsForDownload = useCallback(async () => {
    setGlobalProductDataForDownload({ loading: true, data: null });

    const encodedUrl = `${process.env.REACT_APP_CATALOG_READER_API}`;
    axios
      .get(`${encodedUrl}/global-product/by-type?type=${type}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setGlobalProductDataForDownload({ loading: false, data: res.data });
      })
      .catch((err) => {
        setGlobalProductDataForDownload({ loading: false, data: [] });
        console.error("Download Data: Error", err);
      });
  }, [type]);

  const downloadProduct = () => {
    getGlobalProductsForDownload();
    Modal.success({
      title: "Ready to Download!",
      okButtonProps: { danger: true },
      okText: "Close",
      onOk() {},

      content: (
        <div>
          <p className="mb-2 mt-6 text-red-600">
            Download {globalProductDataForDownload?.data?.products?.length}{" "}
            items of {globalProductDataForDownload?.data?.totalElements}
          </p>
          <p className="mb-4">Max Download Limit 2k</p>

          <CSVLink data={globalProductDataForDownload?.data?.products}>
            <Button shape="round" type="dashed">
              <DownloadOutlined /> Download
            </Button>
          </CSVLink>
        </div>
      ),
    });
  };

  return (
    <>
      <BreadCrumb
        title="Product Catalogue"
        subTitle={`${productData?.data?.totalElements} Catalogue(s)`}
        extra={[
          <Button
            type="dashed"
            shape="circle"
            onClick={() => setShowSearch(!showSearch)}
            key={1}
          >
            <SearchOutlined />
          </Button>,
          <Button
            shape="round"
            type="dashed"
            onClick={downloadProduct}
            loading={globalProductDataForDownload?.loading}
          >
            Download
          </Button>,

          isAllowedService(`Product Catalogue Add`) && (
            <AddBtn
              onClick={() => {
                navigate(`/global-product/create`);
              }}
            />
          ),
          <Button
            type="default"
            shape="round"
            onClick={() => {
              showDrawer();
            }}
            disabled={!selectedProductBarcode.length}
            className="bg-blue-500 text-white"
          >
            <SwapOutlined /> Action
          </Button>,
        ]}
      />

      {showSearch && (
        <div className={styles?.searchBox}>
          <Form layout="inline" form={form} className={styles.formInline}>
            <Form.Item initialValue={type}>
              <Select
                placeholder="Type"
                onChange={(val) => setServiceType(val)}
                value={type}
              >
                {Object.values(jcTypeArray)?.map((type, i) => (
                  <Option value={type} key={i}>
                    {type?.replace("_", " ")}
                  </Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item name="search">
              <AutoComplete
                style={{ width: 300 }}
                onSearch={(e) => handleSearch(e, "product")}
                onSelect={(val: { toString: () => SetStateAction<string> }) =>
                  setKey(val.toString())
                }
                options={productOptions?.list}
                defaultActiveFirstOption={false}
                notFoundContent={
                  productOptions?.loading ? <Spin size="small" /> : null
                }
              >
                <Input.Search
                  placeholder="Search by Name"
                  onSearch={(val) => setKey(val)}
                  enterButton
                  loading={productOptions.loading}
                />
              </AutoComplete>
            </Form.Item>

            <Form.Item name="barcode_search" initialValue={barCode}>
              <AutoComplete
                onSearch={(e) => handleSearch(e, "productByBarcode")}
                onSelect={(val: any) => setBarCode(val)}
                options={productBarcodeOptions?.list}
                defaultActiveFirstOption={false}
                notFoundContent={
                  productBarcodeOptions?.loading ? <Spin size="small" /> : null
                }
              >
                <Input.Search
                  size="large"
                  placeholder="Search by Bar Code"
                  onSearch={(val) => {
                    setBarCode(val);
                  }}
                  enterButton
                  loading={productBarcodeOptions.loading}
                  pattern={`[0-9]`}
                  maxLength={11}
                />
              </AutoComplete>
            </Form.Item>

            <Form.Item name="category_search">
              <TreeSelect
                allowClear
                treeDataSimpleMode
                style={{ width: "100%" }}
                value={categoryId}
                dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
                placeholder="Filter by Category"
                onChange={onChange}
                loadData={onLoadData}
                treeData={treeData}
              />
            </Form.Item>
            <Form.Item name="brand_search">
              <Select
                allowClear
                showSearch
                placeholder="Filter by Brand"
                optionFilterProp="children"
                onChange={(val) => setBrandId(val)}
                onSearch={(e) => handleSearch(e, "brand")}
                filterOption={() => {
                  return true;
                }}
                options={brandOptions?.list}
              ></Select>
            </Form.Item>
            <Form.Item name="range">
              <RangePicker
                defaultValue={
                  range.from != null && range.to != null
                    ? [moment(range.from), moment(range.to)]
                    : null
                }
                onChange={(e, f) => {
                  console.log("momnet", moment.utc(f[0])?.toISOString());
                  setRange({ from: f?.[0], to: f?.[1] });
                }}
                showTime={{
                  hideDisabledOptions: true,
                  defaultValue: [
                    moment("00:00:00", "HH:mm:ss"),
                    moment("23:59:59", "HH:mm:ss"),
                  ],
                }}
                inputReadOnly
                allowClear
              />
            </Form.Item>

            <Form.Item
              name="deleted_search"
              initialValue={
                isActive == "true"
                  ? "ACTIVE"
                  : isActive == "false"
                  ? "INACTIVE"
                  : "ALL"
              }
            >
              <Select
                showSearch
                placeholder="Status"
                onChange={(val) => setIsActive(val as string)}
              >
                <Select.Option value={undefined}>ALL</Select.Option>
                <Select.Option value={"true"} title="ACTIVE">
                  ACTIVE
                </Select.Option>
                <Select.Option value={"false"} title="INACTIVE">
                  INACTIVE
                </Select.Option>
              </Select>
            </Form.Item>
          </Form>
          <Button
            type="primary"
            danger
            size="large"
            htmlType="reset"
            onClick={reseAllFieldData}
          >
            Reset
          </Button>
        </div>
      )}

      <div className={styles.contentWrapper}>
        <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
            <div
              className={
                showSearch ? `content-body-withSearch` : `content-body`
              }
            >
              {confirmLoading && <LoaderFull />}
              {productData?.loading ? (
                <Loader />
              ) : (
                <table className={styles.mainTable}>
                  <thead className="bg-white border-b">
                    <tr>
                      <th scope="col">
                        <div className="checkbox-large">
                          <Checkbox
                            indeterminate={indeterminate}
                            onChange={onCheckAllChange}
                            checked={
                              checkAll ||
                              selectedProductBarcode?.length ===
                                checkedList?.length
                            }
                            className="mr-3"
                            disabled={!productData.data?.products?.length}
                          >
                            Check all
                          </Checkbox>
                        </div>
                      </th>
                      <th scope="col">Cloned</th>
                      <th scope="col">Ratings</th>
                      {isAllowedService(`Product Catalogue Add`) && (
                        <th scope="col">Status</th>
                      )}
                      <th scope="col">Action</th>
                      <th scope="col">Created Info</th>
                      <th scope="col">Updated Info</th>
                    </tr>
                  </thead>

                  <tbody>
                    {productData?.data?.products?.length ? (
                      productData?.data?.products?.map(
                        (product: any, index: any) => (
                          <tr
                            className="border-t hover:bg-gray-100"
                            key={index}
                          >
                            <td>
                              <div className="checkbox-large flex items-center gap-2">
                                <Checkbox
                                  onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    onChangeCheckbox(product?.barCode);
                                  }}
                                  checked={selectedProductBarcode?.find(
                                    (item: any) => product?.barCode === item
                                  )}
                                  value={product?.barCode}
                                >
                                  <Avatar
                                    size={45}
                                    src={product?.productImage}
                                    shape="square"
                                  />
                                </Checkbox>
                                <div className="flex flex-col">
                                  <span className="font-medium text-gray-500">
                                    {product?.name?.slice(0, 50)}
                                  </span>
                                  {product?.barCode && (
                                    <span className="font-light text-gray-500 text-xs flex items-center gap-1">
                                      <BarcodeOutlined /> {product?.barCode}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </td>

                            <td>
                              <span className="font-medium text-gray-500 ml-2">
                                {product?.countUsesByShop} Shops
                              </span>
                            </td>
                            <td>
                              <Rate value={product?.rating || 1} disabled />{" "}
                              <span className="bg-blue-100 text-blue-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-blue-200 dark:text-blue-800 ml-3">
                                {product?.numberOfRating || 0}
                              </span>
                            </td>
                            {isAllowedService(`Product Catalogue Edit`) && (
                              <td>
                                <Switch
                                  checkedChildren={<CheckOutlined />}
                                  unCheckedChildren={<CloseOutlined />}
                                  defaultChecked={!product?.deleted}
                                  onChange={(val, e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    onDelete(
                                      product?.id,
                                      product?.barCode,
                                      val
                                    );
                                  }}
                                  className="mr-1"
                                  loading={
                                    confirmLoading &&
                                    product?.id === confirmLoading
                                  }
                                />
                              </td>
                            )}
                            <td>
                              <div className="flex flex-grow">
                                <Button
                                  shape="round"
                                  type="dashed"
                                  className="bg-white mr-1"
                                  onClick={() =>
                                    navigate(
                                      `/global-product/${product?.slug}/details`
                                    )
                                  }
                                >
                                  <EyeOutlined />
                                </Button>
                                {isAllowedService(`Product Catalogue Add`) && (
                                  <>
                                    <Link
                                      title="Edit Product"
                                      to={`/global-product/${product?.slug}/edit`}
                                      target={
                                        type == "JC_FOOD" ? "_self" : "_blank"
                                      }
                                      className="py-2 px-4 rounded-full mr-1 leading-none bg-white border hover:bg-blue-100 hover:text-gray-600 flex items-center"
                                    >
                                      <EditOutlined />
                                    </Link>

                                    <Button
                                      shape="round"
                                      type="primary"
                                      danger
                                      onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        setSelectedProductForEdit(product);
                                      }}
                                    >
                                      <FileDoneOutlined />
                                    </Button>
                                  </>
                                )}
                              </div>
                            </td>

                            <td>
                              <small className="font-bold uppercase text-gray-500">
                                {product?.createdBy?.name ||
                                  product?.createdBy?.mobileNumber}{" "}
                                -{" "}
                              </small>
                              <small
                                className={`font-capitalize ${
                                  moment().diff(
                                    product?.createdAt,
                                    "minutes"
                                  ) >= 60
                                    ? "text-red-600"
                                    : "text-gray-800"
                                }`}
                              >
                                {moment(product?.createdAt).fromNow()}
                              </small>
                              <br />
                              <span className="name">
                                {moment(product?.createdAt).format("lll")}
                              </span>
                            </td>

                            <td>
                              <small className="font-bold uppercase text-gray-500">
                                {product?.updatedBy?.name ||
                                  product?.updatedBy?.mobileNumber}{" "}
                                -{" "}
                              </small>
                              <small
                                className={`font-capitalize ${
                                  moment().diff(
                                    product?.updatedAt,
                                    "minutes"
                                  ) >= 60
                                    ? "text-red-600"
                                    : "text-gray-800"
                                }`}
                              >
                                {moment(product?.updatedAt).fromNow()}
                              </small>
                              <br />
                              <span className="name">
                                {moment(product?.updatedAt).format("lll")}
                              </span>
                            </td>
                          </tr>
                        )
                      )
                    ) : (
                      <tr>
                        <td>
                          <Empty />
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>

        <Pagination
          {...productData?.data}
          limit={limit}
          page={getPage(loc.search)}
        />
      </div>

      <Modal
        title="Quick Update"
        // placement="right"
        // onClose={onClose}
        onCancel={onClose}
        open={selectedProductForEdit}
        width={650}
        footer={false}
        centered
      >
        <QuickUpdate
          onCloseMethod={onClose}
          visibleData={selectedProductForEdit}
        />
      </Modal>

      <Drawer
        title={
          selectedRadioBtn === "CHANGE_CATEGORY"
            ? "Change Category"
            : selectedRadioBtn === "CLONE_PRODUCT"
            ? "Clone Product"
            : "Take Action"
        }
        placement="right"
        onClose={onClose}
        visible={visible}
        width={400}
      >
        <Radio.Group
          defaultValue={"CLONE_PRODUCT"}
          buttonStyle="solid"
          className="mb-5"
        >
          <Radio.Button
            value="CLONE_PRODUCT"
            onChange={() => {
              setSelectedRadioBtn("CLONE_PRODUCT");
              form.resetFields();
            }}
          >
            Clone Product
          </Radio.Button>
          <Radio.Button
            value="change_category"
            onChange={() => {
              setSelectedRadioBtn("CHANGE_CATEGORY");
              form.resetFields();
            }}
          >
            Change Category
          </Radio.Button>
        </Radio.Group>
        <div className="">
          <Form
            name="control-hooks"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            className="ant-form ant-form-vertical"
            onFinish={onSubmit}
            form={form} // like ref
            layout="vertical"
            autoComplete="off"
          >
            <Form.Item label="Products Barcodes">
              <Input.TextArea
                value={selectedProductBarcode}
                disabled
                rows={8}
              />
            </Form.Item>
            {selectedRadioBtn === "CLONE_PRODUCT" && (
              <>
                <Alert
                  message="Where do you want to CLONE the selected items?"
                  type="warning"
                  banner
                  closable
                  className="mb-4"
                />
                <Form.Item label="Select Merchant">
                  <Select
                    allowClear
                    showSearch
                    placeholder="Select Merchant"
                    optionFilterProp="children"
                    onChange={(val) => setMerchantId(val)}
                    onSearch={(e) => handleSearch(e, "merchant")}
                    filterOption={() => {
                      return true;
                    }}
                    options={merchantOptions.list}
                  ></Select>
                </Form.Item>

                <Form.Item label="Select Shop" name="shopId">
                  <Select
                    mode="multiple"
                    allowClear
                    showSearch
                    placeholder="Select Shop to Clone Product"
                    optionFilterProp="children"
                    onChange={(val) => {
                      setShopId(val);
                    }}
                    onSearch={(e) => handleSearch(e, "shop")}
                    filterOption={() => {
                      return true;
                    }}
                  >
                    {shopsOptions.list.map((shop: any) => (
                      <Option value={shop.value} key={shop.value}>
                        {shop.label}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </>
            )}

            {selectedRadioBtn === "CHANGE_CATEGORY" && (
              <>
                <Alert
                  message="Are you sure to Change Category? After change category the selected items, it will change in Hub or Shop also."
                  type="warning"
                  banner
                  closable
                  className="mb-4"
                />

                <Form.Item name="category_search">
                  <TreeSelect
                    allowClear
                    treeDataSimpleMode
                    style={{ width: "100%" }}
                    value={targetCategoryId}
                    dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
                    placeholder="Filter by Category"
                    onChange={onChange}
                    loadData={onLoadData}
                    treeData={treeData}
                  />
                </Form.Item>

                {/* <Form.Item
                  label="Select Parent Category"
                  name="drawerParentCatId"
                >
                  <Select
                    allowClear
                    showSearch
                    placeholder="Select Parent Category"
                    optionFilterProp="children"
                    onChange={(val) => setDrawerParentCatId(val)}
                    onSearch={(e) => handleSearch(e, "parentCategory")}
                    filterOption={() => {
                      return true;
                    }}
                  >
                    {parentCatOptions?.list?.map((category) => (
                      <Option value={category?.value} key={category?.value}>
                        {category?.label}
                      </Option>
                    ))}
                  </Select>
                </Form.Item> */}

                {/* <Form.Item
                  label="Select Category"
                  name="targetCategoryId"
                  rules={[
                    {
                      required: true,
                      message: "Category is Required!",
                    },
                  ]}
                >
                  <Select
                    allowClear
                    showSearch
                    placeholder="Select Category"
                    optionFilterProp="children"
                    onChange={(val) => setTargetCategoryId(val)}
                    onSearch={(e) => handleSearch(e, "category")}
                    filterOption={() => {
                      return true;
                    }}
                    disabled={!categoriesOptions?.list?.length}
                    options={categoriesOptions?.list}
                  ></Select>
                </Form.Item> */}
              </>
            )}

            <Button
              type="primary"
              danger
              title="Clone Selected Items"
              disabled={(!shopId && !targetCategoryId) || loading}
              size="large"
              htmlType="submit"
              className="mr-3"
              loading={(loading ? "loading" : undefined) as any}
            >
              {selectedRadioBtn === "CHANGE_CATEGORY" ? (
                <>
                  <CopyOutlined /> Change Category
                </>
              ) : (
                <>
                  <CopyOutlined /> Clone Items
                </>
              )}
            </Button>
          </Form>
        </div>
      </Drawer>
    </>
  );
};

export default GlobalProductList;
