import React, { useState, useCallback, useEffect, useRef } from "react";
import {
  Button,
  Form,
  Input,
  Select,
  AutoComplete,
  Spin,
  Checkbox,
} from "antd";
import {
  GoogleMap,
  useJsApiLoader,
  DrawingManager,
  Polygon,
  Marker,
} from "@react-google-maps/api";
import axios from "axios";
import { debounce } from "lodash";
import { useSelector } from "react-redux";
import { SubmitResetBtn } from "../common";
import { useNavigate } from "react-router";
import { useLocation } from "react-router-dom";
import { authenticateToken } from "../../utils/auth";
import BreadCrumb from "../Layouts/Breadcrumb";
import AddBtn from "../common/AddBtn";
import DetailsBtn from "../common/DetailsBtn";
import styles from "../../styles/tailwind/List.module.css";
import {
  getParamValue,
  checkStatesAndPushToHistory,
  jcTypeArray,
} from "../../utils/index";
import ShopMarker from "../../images/icon/ShopMarker.svg";

export default function AreaMap() {
  const navigate = useNavigate();
  const [draw, setDraw] = useState(false);
  const [showSearch, setShowSearch] = useState(true);
  const [shopShow, setShopShow] = useState(true);
  const [sendPoints, setSendPoints] = useState();
  const router = useLocation();
  const [key, setKey] = useState("");
  const loc = useLocation();
  const page = getParamValue(loc.search, "page");
  const [limit, setLimit] = useState(16);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  //const { type } = useSelector((state) => state?.authReducer);
  const [type, setType] = useState("JC_GROCERY");
  const [polygonOptions, setPolygonOptions] = useState({
    loading: false,
    list: null,
  });
  const [neighborhoodData, setNeighborhoodData] = useState({
    loading: false,
    data: null,
  });
  const [allNeighborhood, setAllNeighborhood] = useState({
    loading: false,
    data: null,
  });
  //console.log("all neighbor hood ::", allNeighborhood);
  const [allShops, setAllShops] = useState({
    loading: false,
    data: null,
  });

  //console.log("get all neighborhood ::", allShops);
  const APIKey = "AIzaSyDYp1te-bQEhWE9P9yehRE3biB7LpSEh4U";
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: APIKey,
    libraries: ["drawing"],
  });
  const center = {
    lat: 23.773414356724892,
    lng: 90.41340705259854,
  };
  const containerStyle = {
    width: "100%",
    height: "70vh",
  };

  setTimeout(() => {
    if (isLoaded === true) {
      setDraw(true);
    }
  }, 1000);

  const getNeighborhood = useCallback(async () => {
    let pageReseted = false;
    setNeighborhoodData({ loading: true, data: null });
    const encodedUri = `${process.env.REACT_APP_CATALOG_READER_API}`;
    axios
      .get(
        `${encodedUri}/neighborhood/all` +
          (type ? `?type=${type}` : ``) +
          (page ? `&page=${pageReseted ? 0 : page || 0}` : ``) +
          (limit ? `&limit=${limit}` : ``) +
          (key ? `&key=${key}` : ``),
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        setNeighborhoodData({ loading: false, data: res.data });
      })
      .catch((err) => {
        setNeighborhoodData({ loading: false, data: [] });
      });
  }, [limit, page, type]);
  const getPolygonOptions = useCallback(async (key) => {
    setPolygonOptions({ loading: true, list: null });
    const encodedUri = `${process.env.REACT_APP_CATALOG_READER_API}`;
    axios
      .get(
        `${encodedUri}/neighborhood/all` +
          (type ? `?type=${type}` : ``) +
          (key ? `&key=${key}` : ``) +
          `&page=${page || 0}` +
          `&limit=${limit || 16}`,
        {
          headers: {
            Authorization: `Bearer ${authenticateToken()}`,
          },
        }
      )
      .then((res) => {
        setPolygonOptions({
          loading: false,
          list: res.data?.neighborhoods?.map((neighborhood) => ({
            label: neighborhood.name,
            value: neighborhood.name,
          })),
        });
      })
      .catch((err) => {
        setPolygonOptions({ loading: false, list: [] });
        console.error("Categories: Error", err);
      });
  }, []);

  useEffect(() => {
    getPolygonOptions();
  }, [getPolygonOptions]);
  const getAllShopList = useCallback(async () => {
    let pageReseted = false;
    setAllShops({ loading: true, data: null });
    const encodedUri = `${process.env.REACT_APP_CATALOG_READER_API}`;
    axios
      .get(
        `${encodedUri}/shop` +
          (type ? `?type=${type}` : ``) +
          (page ? `&page=${pageReseted ? 0 : page || 0}` : ``) +
          (limit ? `&limit=${limit}` : ``) +
          (key ? `&key=${key}` : ``),
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        setAllShops({ loading: false, data: res.data });
      })
      .catch((err) => {
        setAllShops({ loading: false, data: [] });
      });
  }, [limit, page, type]);

  useEffect(() => {
    getNeighborhood();
  }, [getNeighborhood]);
  useEffect(() => {
    getAllShopList();
  }, [getAllShopList]);

  const resetData = () => {
    setType("");
  };
  const reseAllFieldData = () => {
    setKey("");
    form.resetFields();
    navigate(window.location.pathname);
    window.location.reload();
  };
  const fetchRef = useRef(0);
  const polygonSearchDebounce = React.useMemo(() => {
    const loadOptions = (value) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;

      if (fetchId !== fetchRef.current) {
        return;
      }
      getPolygonOptions(value);
    };

    return debounce(loadOptions, 800);
  }, [getPolygonOptions]);

  return (
    <div className="add-product drawer-toggle-wrapper">
      <div className="drawer-toggle-inner-wrapper">
        <BreadCrumb
          title={`Neighborhood List`}
          extra={[
            <Button
              type="dashed"
              shape="circle"
              onClick={() => setShowSearch(!showSearch)}
              key={1}
            ></Button>,
            <AddBtn
              onClick={() => {
                navigate("/neighborhood/add");
              }}
            />,
            <DetailsBtn onClick={() => navigate(`/neighborhood/details`)} />,
          ]}
        />
        {showSearch && (
          <div className={styles?.searchBox}>
            <Form layout="inline" form={form} className={styles.formInline}>
              <Form.Item name="search" initialValue={key}>
                <AutoComplete
                  onSearch={getPolygonOptions}
                  onSelect={(val) => setKey(val.toString())}
                  options={polygonOptions?.list}
                  defaultActiveFirstOption={false}
                  notFoundContent={
                    polygonOptions?.loading ? <Spin size="small" /> : null
                  }
                  style={{ width: 300 }}
                >
                  <Input.Search
                    size="large"
                    placeholder="Search by Name"
                    onSearch={(val) => setKey(val)}
                    enterButton
                    loading={polygonOptions.loading}
                    maxLength={11}
                  />
                </AutoComplete>
              </Form.Item>
            </Form>
            <Button
              type="primary"
              danger
              size="large"
              htmlType="reset"
              onClick={reseAllFieldData}
            >
              Reset
            </Button>
          </div>
        )}
        <div className="bg-white p-3">
          <div className="details-area" style={{ minHeight: 650 }}>
            <Checkbox
              type="checkbox"
              defaultChecked={true}
              onChange={(val) => setShopShow(val.target.checked)}
            >
              Shop
            </Checkbox>

            <div className="mb-4">
              {isLoaded && (
                <GoogleMap
                  mapContainerStyle={containerStyle}
                  center={center}
                  zoom={12}
                  onClick={(e) => console.log(e)}
                >
                  {neighborhoodData?.data?.neighborhoods?.map(
                    (neighborhood) => (
                      <Polygon
                        path={neighborhood?.polygon?.points?.map((point) => ({
                          lat: point?.y,
                          lng: point?.x,
                        }))}
                        options={{
                          strokeColor: "red",
                          strokeOpacity: 0.75,
                          strokeWeight: 2,
                          fillColor: "rgb(185 153 255 / 44%)",
                          fillOpacity: 0.6,
                          icons: [
                            {
                              // icon: lineSymbol,
                              offset: "0",
                              repeat: "20px",
                            },
                          ],
                        }}
                      />
                    )
                  )}
                  {shopShow &&
                    allShops?.data?.shops?.map((shop) => (
                      <Marker
                        icon={ShopMarker}
                        title={shop?.name}
                        position={{
                          lat: shop?.location?.y,
                          lng: shop?.location?.x,
                        }}
                        onClick={(e) => {
                          window.open(`/shops/${shop?.id}/details`, `_blank`);
                        }}
                      ></Marker>
                    ))}
                </GoogleMap>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
